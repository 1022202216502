export const listModule = [
    {
        name: "Thống kê",
        to: "/phan-he-thong-ke",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    // {
    //     name: "Quản lý thầu",
    //     to: "/quan-ly-thau",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    // {
    //     name: "Nhập kho",
    //     to: "/phan-he-nhap-kho",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    // {
    //     name: "Xuất kho",
    //     to: "/phan-he-xuat-kho",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    // {
    //     name: "Điều chuyển kho",
    //     to: "/phan-he-dieu-chuyen-kho",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    {
        name: "Kho vật tư ngoại trú",
        to: "/kho-vat-tu-ngoai-tru",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Kho nhà thuốc",
        to: "/kho-nha-thuoc",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Nhà thuốc - thu ngân",
        to: "/phan-he-thu-ngan",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Thẻ kho",
        to: "/the-kho",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Tủ trực",
        to: "/phan-he-tu-truc",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    // {
    //     name: "Quản trị",
    //     to: "/quan-tri-he-thong",
    //     imgSrc: "media/svg/dashboard-icons/system.svg"
    // },
];