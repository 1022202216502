import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_NHAP_KHO = API + "/slip";
const API_PATH_THONG_KE_VAT_TU = API + '/item';

export const addPhieuDieuChuyen = (data: any) => {
  let url = API_NHAP_KHO + `/phieuDieuChuyenKhoAdd`;
  return axios.post(url, data);
};
export const updatePhieuDieuChuyen = (id: string, data: any) => {
  let url = API_NHAP_KHO + `/phieuDieuChuyenKhoAdd/${id}`;
  return axios.put(url, data);
};
export const searchPhieuDieuChuyen = (searchData: any) => {
  const url = `${API_NHAP_KHO}` + `/search`;
  return axios.post(url, searchData);
};

export const deletePhieuDieuChuyen = (id: string) => {
  const url = `${API_NHAP_KHO}` + `/${id}`;
  return axios.delete(url);
};
export const searchListVatTu = (searchObject?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/search`;
  return axios.post(url, searchObject);
};
export const getDsThuocDieuChuyen = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};
export const updateDsThuocDieuChuyen = (data: any) => {
  const url = `${API}/inventory-item/update-items`;
  return axios.put(url, data);
};