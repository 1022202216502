import {
  // OctAutocomplete,
  // OctTextValidator,
} from "@oceantech/oceantech-ui";
import OCTAutocomplete from "../autocompleteOct"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { } from "@oceantech/oceantech-ui";
import axios from "axios";
import React, { FC, useContext, useEffect } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { AppContext } from "../../appContext/AppContext";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import LabelRequired from "../../component/LabelRequired";
import TextField from "../../component/TextField";
import { formatStringDateDTO, formatStringDateToYYYYMMDD } from '../../utils/FormatUtils';
import OCTTextValidator from "../text-validator";
import "./confirmDialog.scss";
import './generateForm.scss';
import {
  COMPONENT_TYPE,
  handleThrowResponseMessage,
  IObject,
  isSuccessfulResponse,
  replaceUrl
} from "./GenerateFormConfig";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
interface IDialogProps {
  warehouseId?:any;
  listFieldAuto?: any;
  onCloseClick?: () => void;
  handleSave?: (data: any) => void;
  onCancelClick?: () => void;
  title?: string;
  message?: string;
  isView?: boolean;
  isUpdate?: boolean;
  itemEdit?: IObject;
  validation?: IObject;
  isSave?: boolean;
  modelID?: any;
  handleSubmit?: any;
  setValues?: any;
  setTouched?: any;
  setFieldValue?: any;
  values?: any;
  errors?: any;
  touched?: any;
  isValid?: any;
  handleChange?: any;
  propsRenderButton?: any;
  DynamicClass?: any;
  isNhapKho?: boolean;
  customComponent?: (props: { data: any }) => React.ReactNode;
}
interface ItemTypeProps {
  autofocus?: boolean;
  clearOnHide?: boolean;
  conditional?: IObject;
  defaultValue?: string;
  hidden?: boolean;
  input?: boolean;
  inputType?: string;
  inputFormat?: string;
  inputMask?: string;
  key: string;
  label?: string;
  lablelPosition?: string;
  multiple?: boolean;
  placeholder?: string;
  persistent?: boolean;
  prefix?: string;
  protected?: boolean;
  properties?: IObject;
  spellcheck?: boolean;
  suffix?: string;
  unique?: boolean;
  width?: number;
  offset?: number;
  push?: number;
  pull?: number;
  type?: string;
  value?: string;
  tag?: keyof JSX.IntrinsicElements;
  tags?: string[];
  validate?: IObject;
  columns?: IObject;
  values?: IObject;
  components?: ItemTypeProps[];
  data?: IObject;
  filePattern?: string;
  fileMaxSize?: string;
  fileMinSize?: string;
  format?: string;
  url?: string;
  fields?: any;
  content?: string;
  hideLabel?: boolean;
  inline?: boolean;
  className?: string;
  overlay?: any;
  searchObject?: any;
  rows: number;
  customClass?: string;
  disabled: boolean;
}
const GenerateFormComponent: FC<IDialogProps> = (props) => {
  const {
    warehouseId,
    isView,
    modelID,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
    handleChange = () => { },
    listFieldAuto,
    itemEdit = null,
    customComponent,
    DynamicClass,
    propsRenderButton,
    isNhapKho = false,
  } = props;

  const { lang } = useMultiLanguage();
  const { setIsLoading } = useContext(AppContext);

  useEffect(() => {
    Object.keys(itemEdit || {})?.length && setValues({
      ...values,
      [modelID]: itemEdit
    })
  }, [itemEdit]);

  const handleChangeFile = async (e: any, item: ItemTypeProps) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("uploadedFile", file);

    if (item.url) {
      try {
        setIsLoading(true);
        let res = await uploadFile(item?.url, formData);
        const data = res.data;
        if (isSuccessfulResponse(res.status)) {
          setIsLoading(false);
          let attachment = data.file || data?.data;
          let attachments = [];
          let fileDescriptionIds = [];

          attachments.push(attachment);
          fileDescriptionIds.push(attachment?.id);
          await setFieldValue(e.target.name, fileDescriptionIds);
          toast.success(lang("GENERAL.SUCCESS"));
        } else {
          handleThrowResponseMessage(res);
        }
      } catch {
        toast.error(lang("GENERAL.ERROR"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const uploadFile = (url: string | undefined | null, formData: any) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.post(url || "", formData, config);
  };

  const handleChangeCheck = (name: string, value: any, modelID: string, e: any) => {
    setFieldValue(modelID, {
      ...values?.[modelID],
      [name]: value ? "1" : "0",
    });
  };

  const handleChangeValueText = (name: string, value: any, modelID: string, dataComponent?: any) => {
    let valueConvert = value
    if (dataComponent?.inputType === "number") {
      valueConvert = Number(value)
    }
    if (dataComponent?.type === "datetime") {
      valueConvert = formatStringDateDTO(value)
    }
    setFieldValue(modelID, {
      ...values?.[modelID],
      [name]: valueConvert,
    });
  };

  const handleChangeValueSelect = (e: any, modelID: string, dataComponent?: any) => {
    let stringName = dataComponent?.key + "Name";
    let stringId = dataComponent?.key + "Id";
    let stringCode = dataComponent?.key
    setFieldValue(modelID, {
      ...values?.[modelID],
      dataItem: e,
      [stringName]: e?.name,
      [stringCode]: e?.code,
      [stringId]: e?.id,
    });
  };

  const handleGetDataByUrlData = (urlData?: string, searchObject?: any) => {
    if (urlData === 'item/search') {
      let url = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL) + `/${urlData}`;
      return axios.post(url, {});
    };
    if (urlData) {
      let url = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL) + `/${urlData}`;
      return axios.get(url);
    }
  };

  const handleChangeSelectBoxes = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    source: string
  ) => {
    let newArray = values?.[source] || [];
    newArray[index].value = e.target.checked;
    setFieldValue(source, newArray);
  };

  const renderHTML = (dataForm: any, configKey?: string) => {
    if (dataForm?.components || dataForm?.[configKey || "components"]) {
      let newArray = dataForm?.components || dataForm?.[configKey || ""] || [];

      if (Object.prototype.toString.call(newArray) === "[object Object]") {
        newArray = Object.entries(newArray).map(([key, value]) => {
          return value;
        });
      }

      let filteredComponents = newArray?.filter(
        (item: ItemTypeProps) => item.type !== COMPONENT_TYPE.BUTTON
      );
      return filteredComponents?.map((dataComponent: ItemTypeProps) => {
        switch (dataComponent?.type) {
          case COMPONENT_TYPE.EMAIL:
          case COMPONENT_TYPE.PASSWORD:
          case COMPONENT_TYPE.TEXTFIELD:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces pt-8 ${dataComponent?.customClass}`}
                >
                  <TextField
                    hideLabel={dataComponent?.hideLabel}
                    lable={dataComponent?.label}
                    name={dataComponent?.key}
                    type={dataComponent.inputType}
                    onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID)}
                    value={values?.[modelID]?.[dataComponent?.key] || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                    disabled={isView || dataComponent.disabled}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.COLUMNS:
            return (
              <Row className={`generate-form-${dataComponent.customClass}`}>
                {dataComponent?.columns &&
                  dataComponent?.columns?.length > 0 &&
                  dataComponent?.columns?.map((dataColumn: ItemTypeProps, index: number) => {
                    return (
                      <Col className={`${modelID}-${dataComponent.key}-${index}`} lg={dataColumn?.width}>{renderHTML(dataColumn)}</Col>
                    );
                  })}
              </Row>
            );
          case COMPONENT_TYPE.FILE:
            return (
              <>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="text-lable-input lable m-0">
                    {dataComponent?.label}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name={dataComponent.key}
                    max={dataComponent?.fileMaxSize}
                    min={dataComponent?.fileMinSize}
                    accept={dataComponent?.filePattern}
                    onChange={(e) => handleChangeFile(e, dataComponent)}
                    value={values?.[dataComponent?.key]}
                  />
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.DAY:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Row>
                  <Col
                    lg={3}
                    className="spaces pr-0 mb-3 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      lable={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={values?.[dataComponent?.key]}
                      touched={touched?.[dataComponent?.key]}
                      errors={errors?.[dataComponent?.key]}
                      placeholder={""}
                    />
                  </Col>
                  <Col
                    lg={3}
                    className="spaces mb-3 pl-0 pr-0 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      lable={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={values?.[dataComponent?.key]}
                      touched={touched?.[dataComponent?.key]}
                      errors={errors?.[dataComponent?.key]}
                      placeholder={""}
                    />
                  </Col>
                  <Col
                    lg={3}
                    className="spaces mb-3 pl-0 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      lable={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={values?.[dataComponent?.key]}
                      touched={touched?.[dataComponent?.key]}
                      errors={errors?.[dataComponent?.key]}
                      placeholder={""}
                    />
                  </Col>
                  {dataComponent?.key == "ngaySinh" &&
                    <>
                      <Col
                        lg={1}
                        className="spaces mb-3 pl-0 pt-8"
                      >
                        <div className="spaces d-flex flex-center fw-bold">
                          -
                        </div>
                      </Col>

                      <Col
                        lg={2}
                        className="spaces mb-3 pl-0 pt-8"
                      >
                        <OCTTextValidator
                          hideLabel={dataComponent?.hideLabel}
                          lable={""}
                          name={dataComponent?.key}
                          type={"text"}
                          onChange={handleChange}
                          isRequired={dataComponent?.validate?.required}
                          value={values?.[dataComponent?.key]}
                          touched={touched?.[dataComponent?.key]}
                          errors={errors?.[dataComponent?.key]}
                          placeholder={""}
                        />
                      </Col>
                    </>
                  }
                </Row>
              </>
            );
          case COMPONENT_TYPE.TIME:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Control
                  type="time"
                  placeholder="Time"
                  onChange={handleChange}
                  value={values?.[dataComponent?.key]}
                />

              </>
            );
          case COMPONENT_TYPE.DATETIME:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces pt-8 ${dataComponent?.customClass}`}
                >
                  <TextField
                    hideLabel={dataComponent?.hideLabel}
                    lable={dataComponent?.label}
                    name={dataComponent?.key}
                    type={"date"}
                    onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID, dataComponent)}
                    value={formatStringDateToYYYYMMDD(values?.[modelID]?.[dataComponent?.key]) || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                    disabled={isView || dataComponent.disabled}
                  />
                </Col>

              </>
            );
          case COMPONENT_TYPE.NUMBER:
          case COMPONENT_TYPE.PHONE:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces pt-8 ${dataComponent?.customClass}`}
                >
                  <TextField
                    hideLabel={dataComponent?.hideLabel}
                    lable={dataComponent?.label}
                    name={dataComponent?.key}
                    type={"number"}
                    onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID, dataComponent)}
                    // isRequired={dataComponent?.validate?.required}
                    value={values?.[modelID]?.[dataComponent?.key] || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                    disabled={isView || dataComponent.disabled}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.TEXTAREA:

            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces ${dataComponent?.customClass}`}
                >
                  <TextField
                    name={dataComponent?.key}
                    className={`spaces py-4 min-h-${30 * dataComponent.rows + (5 * (dataComponent.rows - 1))}px`}
                    labelClassName="ps-2"
                    onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID)}
                    as="textarea"
                    disabled={isView}
                    value={values?.[modelID]?.[dataComponent?.key] || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    label={!dataComponent?.hideLabel && dataComponent?.label}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.CHECKBOX:
            return (
              <>
                <Form.Group className="spaces pt-10 form-check-label-black" controlId={dataComponent?.key}>
                  <FormCheck
                    name={dataComponent.key}
                    type="checkbox"
                    label={dataComponent.hideLabel ? "" : dataComponent?.label}
                    className="d-flex align-items-center spaces gap-7"
                    checked={values?.[modelID]?.[dataComponent?.key] === "1"}
                    onChange={(e: any) => handleChangeCheck(e.target.name, e.target.checked, modelID, e)}
                  />
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.SELECTBOXES:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Group controlId={dataComponent?.key} className={`${dataComponent?.inline && 'd-flex gap-10'}`}>
                  {dataComponent?.values?.map(
                    (dataItem: any, index: number) => (
                      <Form.Check
                        type="checkbox"
                        label={dataItem?.label}
                        checked={Boolean(
                          values[dataComponent?.key]?.[index]?.value
                        )}
                        onChange={(e) =>
                          handleChangeSelectBoxes(e, index, dataComponent?.key)
                        }
                      />
                    )
                  )}
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.RADIO:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Group className="d-flex spaces pt-8" controlId="formBasicCheckbox">
                  {dataComponent?.values?.map((dataItem: any) => (
                    <Form.Check
                      inline
                      type="radio"
                      label={dataItem?.label}
                      value={dataItem.value}
                      name={dataComponent?.key}
                      checked={
                        values?.[dataComponent?.key] === dataItem.value
                      }
                      onChange={handleChange}
                    />
                  ))}
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.SELECT:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces ${dataComponent?.customClass}`}
                >
                  <AutocompleteObjectV2
                    options={[]}
                    isSearchDefauilt={true}
                    name={dataComponent?.key}
                    onChange={(e: any) => handleChangeValueSelect(e, modelID, dataComponent)}
                    searchFunction={() =>
                      handleGetDataByUrlData(
                        replaceUrl(dataComponent?.data?.url || ""),
                        dataComponent?.searchObject || {}
                      )
                    }
                    value={values?.[modelID]?.[dataComponent?.key] || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent?.placeholder || ""}
                    searchObject={dataComponent?.searchObject || {}}
                    className="autocomplete-custom-renderform mt-8 radius spaces width-100 h-29"
                    isDisabled={isView}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.BUTTON:
            return (
              <>
                <Button>
                  {lang(`BTN.${dataComponent?.label?.toUpperCase()}`)}
                </Button>
              </>
            );
          case COMPONENT_TYPE.CONTENT:
            return (
              <>
                <div className="spaces pt-10 ">
                  <CKEditor
                    editor={ClassicEditor}
                    disabled={isView}
                    onChange={(event: any, editor: any) => {
                      setFieldValue(dataComponent?.key, editor.getData());
                    }}
                    data={values?.[dataComponent?.key] || ""}
                  />
                </div>
              </>
            );
          case COMPONENT_TYPE.HTML:
            return (
              <div className="spaces pt-10 min-w-135">
                {dataComponent?.tag == "span" ?
                  <div className="">
                    <LabelRequired className={`min-w-100px ${dataComponent?.className}`} label={dataComponent?.content || ""} />
                  </div> :
                  (
                    dataComponent?.tag == "p" ?
                      <div className={`spaces  mt-1  ${dataComponent?.className}`}>
                        <LabelRequired className="min-w-100px" isRequired={dataComponent?.className?.includes('isRequired')} label={dataComponent?.content || ""} />
                      </div>
                      : <div className="hyperlink">{dataComponent?.label}</div>
                  )
                }
              </div>
            );
          case COMPONENT_TYPE.CUSTOM:
            return customComponent ? customComponent({
              data: {
                warehouseId:warehouseId,
                componentName: dataComponent.key,
                setThuocSelected: propsRenderButton,
                values: values?.[modelID] || "",
                DynamicClass: DynamicClass,
                isNhapKho: isNhapKho,
              }
            }) : <></>;
          default:
            return <></>;
        }
      });
    } else {
      return <></>;
    }
  };

  return (
    <Form >
      {renderHTML(listFieldAuto)}
    </Form>
  );
};

export default GenerateFormComponent;
