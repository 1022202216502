import React, { useContext, useEffect, useState } from "react";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTMLNoValid"
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE } from "../../../../utils/Constant";
import { getDetailThongKe } from "../../../services/ThongKeServices"
import { useFormikContext } from "formik";
import { VatTu } from "../models/ThemMoiVatTuModel";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../../PhanHeThongKe"

type Props = {
  modelID?: any,
};

const FormThongTinChung = ({
  modelID,
}: Props) => {


  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    handleChange
  } = useFormikContext<VatTu>();
  const { setIsLoading } = useContext(AppContext);
  const { nhomVatTu,dataVatTuEdit } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [isView, setIsView] = useState<any>(false);
  const [data, setData] = useState<any>({});
  const [model, setModel] = useState<any>(1);

  const constModel = [
    "thongTinChung",
    "thongTinChungVatTu",
    "thongTinChungMau",
    "thongTinChungHangHoa",
  ]
  const ConvertModel = () => {
    // debugger
    if (nhomVatTu?.id) return constModel[nhomVatTu?.id - 1];
    return "thongTinChung"
  }

  useEffect(() => {
    if (nhomVatTu?.id) {
      getListform();
      // setModel(nhomVatTu?.id || 1);
    }
  }, [])

  const handlerSaveForm = async (value: any) => {
  }
  const getListform = async () => {
    let data = await generateForm(`${ConvertModel()}`, setIsLoading)
    setListJsonGenerate(data)
  }

  return (
    <div className="bg-white spaces mb-6 pb-15 fl-1 ml-10 mr-10">
      <GenerateFormHTML
        isView={isView}
        listFieldAuto={listJsonGenerate}
        itemEdit={dataVatTuEdit?.thongTinChung}
        isSave={false}
        isUpdate={true}
        modelID={ConvertModel()}
        handleSubmit={handleSubmit}
        setValues={setValues}
        setTouched={setTouched}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
        isValid={isValid}
        handleChange={handleChange}
      />
    </div>
  );
};

export default FormThongTinChung;
