import { ComponentPropsWithoutRef, forwardRef } from 'react';

interface IframeProps extends ComponentPropsWithoutRef<'iframe'> { }

const FilePreview = forwardRef<HTMLIFrameElement, IframeProps>(
    ({ src, title = '' }: IframeProps, ref) => {
        return (
            <div className="h-100">
                <iframe
                    ref={ref}
                    title={title}
                    src={`${src ? `${src}#toolbar=0&navpanes=0` : ''}`}
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
);

export default FilePreview;
