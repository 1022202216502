import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { DanhSachPhieuInterface } from "../models/DieuChuyenModel";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { formatDateToDDMMYYYY } from "../../utils/FormatUtils";

export const DanhSachPhieuColumns: ReadonlyArray<
  Column<DanhSachPhieuInterface>
> = [
  {
    Header: (props) => (
      <TableCustomHeader<DanhSachPhieuInterface>
        tableProps={props}
        title="STT"
        className="text-center text-light max-w-35px"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center"
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<DanhSachPhieuInterface>
        tableProps={props}
        title="TT"
        className="text-center text-light max-w-35px"
      />
    ),
    id: "custom",
    Cell: ({ ...props }) => (
      <div className="text-center">
        <i
          className="bi bi-circle-fill"
          style={{ color: props.data[props.row.index]?.color }}
        ></i>
      </div>
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<DanhSachPhieuInterface>
        tableProps={props}
        title="Mã phiếu"
        className="text-center text-white align-middle bg-pri max-w-100px"
      />
    ),
    id: "maPhieu",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-left s-2"
        data={props.data[props.row.index]?.code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<DanhSachPhieuInterface>
        tableProps={props}
        title="Ngày lập phiếu"
        className="text-center text-white align-middle bg-pri min-w-80px max-w-100"
      />
    ),
    id: "ngayLap",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center s-2"
        data={formatDateToDDMMYYYY(props.data[props.row.index]?.dateCreate)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<DanhSachPhieuInterface>
        tableProps={props}
        title="Nơi yêu cầu"
        className=" text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "noi-yeu-cau",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={props.data[props.row.index]?.requestWhName}
        className="text-left"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<DanhSachPhieuInterface>
        tableProps={props}
        title="Nơi chuyển"
        className=" text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "noi-chuyen",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-left s-2"
        data={props.data[props.row.index]?.transferWhName}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<DanhSachPhieuInterface>
        tableProps={props}
        title="Ghi chú"
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "note",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center s-2"
        data={props.data[props.row.index]?.note}
      />
    ),
  },
];

export const summaryStatus = [
  {
    statusName: "Chờ duyệt",
    summary: 3,
    color: "#28A745",
  },
  {
    statusName: "Đã duyệt",
    summary: 3,
    color: "#DC3545",
  },
];
