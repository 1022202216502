import { Formik, FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import FormThongKe from "./components/FormThongKe";



export interface PhanHeThongKeContextProps {
    dataVatTuView: any,
    setDataVatTuView: any,
    dataVatTuEdit: any,
    setDataVatTuEdit: any,
    nhomVatTu: {
        id?: number | undefined;
        [key: string]: any;
    },
    setNhomVatTu: any,
}

const initialContext = {
    dataVatTuView: {},
    setDataVatTuView: () => { },
    dataVatTuEdit: {},
    setDataVatTuEdit: () => { },
    nhomVatTu: {  },
    setNhomVatTu: () => { },
}

export const PhanHeThongKeContext = createContext<PhanHeThongKeContextProps>(initialContext)

export const PhanHeThongKe: FC = () => {
    const [dataVatTuView, setDataVatTuView] = useState({});
    const [dataVatTuEdit, setDataVatTuEdit] = useState({})
    const [nhomVatTu, setNhomVatTu] = useState({})

    useEffect(() => {
    }, []);
    return (
        <Formik<any>
            initialValues={{}}
            onSubmit={() => { }}
        >
            <PhanHeThongKeContext.Provider
                value={{
                    dataVatTuView,
                    setDataVatTuView,
                    dataVatTuEdit,
                    setDataVatTuEdit,
                    nhomVatTu,
                    setNhomVatTu,
                }}
            >
                <div className="bg-white">

                    <div className="flex-1 flex flex-column">
                        <div className="py-1 ">
                            <FormThongKe
                            />
                        </div>
                    </div>


                </div>

            </PhanHeThongKeContext.Provider>
        </Formik>
    )
}

export default PhanHeThongKe;
