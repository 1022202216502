
export interface ObjectSelectAutocomplete {
    id?: string,
    name?: string,
    code?: string | number
}
export const invitialObjectSelectAutocomplete: ObjectSelectAutocomplete = {
    id: null || "",
    name: null || "",
    code: null || "",
}
export interface VatTu {
    id: string,
    thongTinChung: thongTinChungInterface,
    // thongTinKeToanThuocAdd: thongTinKeToanInterface,
    // thongTinCoBanThuocAdd: thongTinCoBanInterface,
    // thongTinXuatNhapKhoThuocAdd: thongTinXuatNhapKhoInterface,
    // thongTinLamSangThuocAdd: thongTinLamSangInterface,
    // thongTinKhacThuocAdd: thongTinKhacInterface,
};

export interface thongTinChungInterface {
    materialType: string | null,
    code: string | null,
    BHYTMaterialCode: string | null,
    name: string | null,
    hospitalMaterialName: string | null,
    BHYTMaterialName: string | null,
    unitofMeasure: string | null,
    BYTSerialNumber: string | null,
    BYTMaterialCode: string | null,
    supplier: string | null,
    manufacturer: string | null,
    countryofOrigin: string | null,
    registrationNumber: string | null,
    consignment: string | null,
    expiryDate: string | null,
    packaging: string | null,
    wareHouse: string | null,
}
export interface thongTinCoBanInterface {
    dosage: string | null,
    concentration: string | null,
    volume: string | null,
    solvent: string | null,
    actIngCode: string | null,
    actIngName: string | null,
    ROA: string | null,
    usageIns: string | null,
    otgInsured: string | null,
    brandName: string | null,
    barcode: string | null,
    aTCCode: string | null,
    dnTender: string | null,
    tpNum: string | null,
    tenderGroup: string | null,
    tenderYear: string | null,
    tenderSerialNumber: string | null,
    tenderExpiryDate: string | null,
    tenderQuantityWarning: string | null,
    nationalDrugCode: string | null,
    nationalTender: string | null,
    provincialTender: string | null,
    institutionalTender: string | null,
    notes: string | null,
    maximumDose: string | null,
    storageConditions: string | null,
    distributionFacility: string | null,
}
export interface thongTinKeToanInterface {
    drugGroup: string | null,
    drugType: string | null,
    BHYTCostGroup: string | null,
    revenueAccountGroup: string | null,
    reportingGroup: string | null,
    alwSelectgrpre: string | null,
    BHYTPrice: string | null,
    hospitalFeePrice: string | null,
    requestedPrice: string | null,
    foreignPrice: string | null,
    pCIN: string | null,
    pCON: string | null,
    blcServiceobjtrans: string | null,
    assignedDepartment: string | null,
    assignedRoom: string | null,
    purchasePrice: number | null,
    VATPurchase: string | null,
    sellingPrice: number | null,
    VATSale: string | null,
    refComgr: string | null,
    accountingCode: string | null,
}
export interface thongTinXuatNhapKhoInterface {
    expiryDateWarning: string | null,
    stockWarning: string | null,
    availabilityWarning: string | null,
    useWarningafnumday: string | null,
}
export interface thongTinLamSangInterface {
    ManagementGroup: string | null,
    ResearchGroup: string | null,
    DiseaseGroup: string | null,
    TPG: string | null,
    ABCVENGroup: string | null,
    pharGroup: string | null,
    pharSubgroup: string | null,
    accomDrugs: string | null,
    accomActingre: string | null,
    Antibiotic: string | null,
    SyntheticDrug: string | null,
    presDrug: string | null,
    presPediatricmed: string | null,
    tradMedicineingre: string | null,
    tradMedicineprep: string | null,
    DietarySupplement: string | null,
    SponsoredDrug: string | null,
    presDosageuv: string | null,
    CreateInfusionForm: string | null,
    presVolumeaccominf: string | null,
    dPrintactingredos: string | null,
    AerosolMedication: string | null,
    reqConsulrecord: string | null,
    reqReturndrugpack: string | null,
    dPrintactingredrugname: string | null,
    dPrintdcdrugname: string | null,
}
export interface thongTinKhacInterface {
    allDis: string | null,
    allDocadjpayrate: string | null,
    exXMLaccdec5937: string | null,
    allPresquan0: string | null,
    regGroup: string | null,
    prosharGroup: string | null,
    treatshtArrangegrp: string | null,
    Lock: string | null,
    stpSuppent: string | null,
    numTreatshtser: string | null,
    autorndQuan0: string | null,
}
export const initialValuesThongTinKeToan: thongTinKeToanInterface = {
    drugGroup: null || "",
    drugType: null || "",
    BHYTCostGroup: null || "",
    revenueAccountGroup: null || "",
    reportingGroup: null || "",
    alwSelectgrpre: null || "",
    BHYTPrice: null || "",
    hospitalFeePrice: null || "",
    requestedPrice: null || "",
    foreignPrice: null || "",
    pCIN: null || "",
    pCON: null || "",
    blcServiceobjtrans: null || "",
    assignedDepartment: null || "",
    assignedRoom: null || "",
    purchasePrice: 0,
    VATPurchase: null || "",
    sellingPrice: 0,
    VATSale: null || "",
    refComgr: null || "",
    accountingCode: null || "",
}
export const initialValuesThongTinChung: thongTinChungInterface = {
    materialType: null || "",
    code: null || "",
    BHYTMaterialCode: null || "",
    name: null || "",
    hospitalMaterialName: null || "",
    BHYTMaterialName: null || "",
    unitofMeasure: null || "",
    BYTSerialNumber: null || "",
    BYTMaterialCode: null || "",
    supplier: null || "",
    manufacturer: null || "",
    countryofOrigin: null || "",
    registrationNumber: null || "",
    consignment: null || "",
    expiryDate: null || "",
    packaging: null || "",
    wareHouse: null || "",
}
export const initialValuesThongTinCoBan: thongTinCoBanInterface = {
    dosage: null || "",
    concentration: null || "",
    volume: null || "",
    solvent: null || "",
    actIngCode: null || "",
    actIngName: null || "",
    ROA: null || "",
    usageIns: null || "",
    otgInsured: null || "",
    brandName: null || "",
    barcode: null || "",
    aTCCode: null || "",
    dnTender: null || "",
    tpNum: null || "",
    tenderGroup: null || "",
    tenderYear: null || "",
    tenderSerialNumber: null || "",
    tenderExpiryDate: null || "",
    tenderQuantityWarning: null || "",
    nationalDrugCode: null || "",
    nationalTender: null || "",
    provincialTender: null || "",
    institutionalTender: null || "",
    notes: null || "",
    maximumDose: null || "",
    storageConditions: null || "",
    distributionFacility: null || "",
}
export const initialValuesThongTinXuatNhapKho: thongTinXuatNhapKhoInterface = {
    expiryDateWarning: null || "",
    stockWarning: null || "",
    availabilityWarning: null || "",
    useWarningafnumday: null || "",
}
export const initialValuesThongTinLamSang: thongTinLamSangInterface = {
    ManagementGroup: null || "",
    ResearchGroup: null || "",
    DiseaseGroup: null || "",
    TPG: null || "",
    ABCVENGroup: null || "",
    pharGroup: null || "",
    pharSubgroup: null || "",
    accomDrugs: null || "",
    accomActingre: null || "",
    Antibiotic: null || "",
    SyntheticDrug: null || "",
    presDrug: null || "",
    presPediatricmed: null || "",
    tradMedicineingre: null || "",
    tradMedicineprep: null || "",
    DietarySupplement: null || "",
    SponsoredDrug: null || "",
    presDosageuv: null || "",
    CreateInfusionForm: null || "",
    presVolumeaccominf: null || "",
    dPrintactingredos: null || "",
    AerosolMedication: null || "",
    reqConsulrecord: null || "",
    reqReturndrugpack: null || "",
    dPrintactingredrugname: null || "",
    dPrintdcdrugname: null || "",
}
export const initialValuesThongTinKhac: thongTinKhacInterface = {
    allDis: null || "",
    allDocadjpayrate: null || "",
    exXMLaccdec5937: null || "",
    allPresquan0: null || "",
    regGroup: null || "",
    prosharGroup: null || "",
    treatshtArrangegrp: null || "",
    Lock: null || "",
    stpSuppent: null || "",
    numTreatshtser: null || "",
    autorndQuan0: null || "",
}
export const initialValuesThemMoiVatTu: VatTu = {
    id: "",
    thongTinChung: initialValuesThongTinChung,
    // thongTinKeToanThuocAdd: initialValuesThongTinKeToan,
    // thongTinCoBanThuocAdd: initialValuesThongTinCoBan,
    // thongTinXuatNhapKhoThuocAdd: initialValuesThongTinXuatNhapKho,
    // thongTinLamSangThuocAdd: initialValuesThongTinLamSang,
    // thongTinKhacThuocAdd: initialValuesThongTinKhac,
};


