import { useContext, useEffect, useState } from "react";
import { OctTable } from "@oceantech/oceantech-ui";
import TextField from "../../component/TextField";
import { PhanHeDieuChuyenContext } from "../PhanHeDieuChuyen";
import { Button } from "react-bootstrap";
import AutocompleteObjectV2 from "../../component/AutocompleteObjectV2";
import { searchListVatTu } from "../services/services";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";
import { useFormikContext } from "formik";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../utils/Constant";
import { AppContext } from "../../appContext/AppContext";

interface Props {}

const TableThongTinDieuChuyen = (props: Props) => {
  const { setDsDieuChuyen, dsDieuChuyen, onlyView } = useContext(PhanHeDieuChuyenContext);
  const { setIsLoading } = useContext(AppContext);
  const { values } = useFormikContext<any>();
  const [listVatTu, setListVatTu] = useState<any[]>();

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchListVatTu({
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
        warehouseCode: values?.dieuChuyen?.requestWh,
      });
      setListVatTu(data?.data?.content);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const data = [...dsDieuChuyen];
    if (data[index]) {
      data[index] = {
        ...data[index],
        [name]: value,
      };

      setDsDieuChuyen(data);
    }
  };

  const handleChangeSelect = (options: any, index: number) => {
    const data = [...dsDieuChuyen];
    data[index] = options ? { item: options } : {};
    setDsDieuChuyen(data);
  };

  const handleAddRow = () => {
    const data = [...dsDieuChuyen];
    const newData = data.concat([{}]);
    setDsDieuChuyen(newData);
  };

  const hanldeDeleteRow = (index: number) => {
    const data = [...dsDieuChuyen].filter((_: any, i: number) => i !== index);
    setDsDieuChuyen(data);
  };

  const columns = [
    ...(!onlyView
      ? [
          {
            name: "",
            field: "",
            headerStyle: {
              minWidth: 50,
              maxWidth: 50,
            },
            cellStyle: {
              textAlign: "center",
            },
            render: (row: any, index: number, stt: number) =>
              dsDieuChuyen.length > 1 && (
                <i
                  onClick={(e) => hanldeDeleteRow(index)}
                  className="bi bi-trash3-fill text-danger spaces ml-10  "
                ></i>
              ),
          },
        ]
      : []),

    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => onlyView ? <></> : (
        <div className="position-relative">
          <span>{stt}</span>
          {index + 1 === dsDieuChuyen.length && (
            <Button className="btn-fill custom-button" onClick={handleAddRow}>
              <i className="bi bi-plus"></i>
            </Button>
          )}
        </div>
      ),
    },
    {
      name: "Tên vật tư",
      field: "",
      headerStyle: {
        minWidth: 220,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => (
        <AutocompleteObjectV2
          options={listVatTu || []}
          isSearchDefauilt={true}
          name={"itemId"}
          onChange={(options: any) => handleChangeSelect(options, index)}
          value={row.item || ""}
          className="autocomplete-custom-renderform  radius spaces width-100 h-29"
          isDisabled={onlyView}
        />
      ),
    },
    {
      name: "ĐVT",
      field: "",
      headerStyle: {
        minWidth: 60,
        maxWidth: 60,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.item?.unitofMeasure || "",
    },
    {
      name: "Số thầu",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.item?.soThau || "",
    },
    {
      name: "Số lượng",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => (
        <TextField
          name="totalQuantity"
          type="number"
          value={row.totalQuantity || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, index)
          }
          disabled={onlyView}
        />
      ),
    },
    {
      name: "Số lô",
      field: "",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.consignment || "",
    },
    {
      name: "Ngày hết hạn",
      field: "",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) =>
        formatDateToDDMMYYYY(row.item?.expiryDate) || "",
    },
    {
      name: "Đơn giá",
      field: "",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row.item?.unitPrice) || "",
    },
    {
      name: "Thành tiền",
      field: "",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => "",
    },
    {
      name: "Ghi chú",
      field: "",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => (
        <TextField
          name="note"
          value={row.note || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, index)
          }
          disabled={onlyView}
        />
      ),
    },
  ];

  useEffect(() => {
    updatePageData();
  }, [values?.dieuChuyen?.requestWh]);

  useEffect(() => {
    if (!dsDieuChuyen.length) {
      setDsDieuChuyen([{}]);
    }
  }, [dsDieuChuyen.length]);

  return (
    <div className="spaces mt-12 border-table custom-table">
      <h4 className="spaces mt-8 title">Danh sách vật tư</h4>
      <OctTable
        className="table-dieu-chuyen custom-header-table"
        columns={columns}
        data={dsDieuChuyen}
        noToolbar
        clearToolbar
        noPagination
      />
    </div>
  );
};

export default TableThongTinDieuChuyen;
