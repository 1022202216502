import { FC, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Column } from "react-table";
import { AppContext } from "../../appContext/AppContext";
import InputSearch from "../../component/InputSearch";
import SelectTree from "../../component/SelectTree";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { DEFAULT_PAGE_INDEX, CODE, SELECTION_MODE } from "../../utils/Constant";
import { formatMoney, formatDateToDDMMYYYY } from "../../utils/FormatUtils";
import {
  handlePagesChange,
  handleRowsPerPageChange,
  rowsForPage,
} from "../../utils/PageUtils";
import { TreeQuanLyThau } from "../constants/ColumnsThongKe";
import { searchGoiThau, getDetailGoiThau, searchVT } from "../services/QuanLyThauServices";
import { ListGoiThauInterface } from "../models/QuanLyThauModel";
import "../QuanLyThau.scss";
import DialogThemMoiGoiThau from "./DialogThemMoiGoiThau";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { QuanLyThauContext } from "../QuanLyThau";


export const FormQuanLyThau: FC = () => {
  const { setIsLoading } = useContext(AppContext);
  const { detailGoiThau, setDetailGoiThau } = useContext(QuanLyThauContext);
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [treeData, setTreeData] = useState<any>(TreeQuanLyThau);
  const [rowSelected, setRowSelected] = useState<any>([]);
  const [listGoiThau, setListGoiThau] = useState<any[]>();
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<any>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowsPerPageVT, setRowsPerPageVT] = useState<number>(20);
  const [pageVT, setPageVT] = useState<number>(DEFAULT_PAGE_INDEX);
  const [dsVatTu, setDsVatTu] = useState<any>([]);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [configTableVT, setConfigTableVT] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });

  const handleCloseDialog = () => {
    setDetailGoiThau({});
    setOpenDialogThemMoi(false);
  };

  const handleSelectData = (row: any) => {
    if (row.length) {
      handlerGetDetailGoiThau(row[0]?.id);
      getListVatTu(row[0]?.id)
    }
  };

  useEffect(() => {
    // setListGoiThau(LIST_GOI_THAU);
    setTreeData(TreeQuanLyThau);
    updatePageData()
  }, []);


  const updatePageData = async () => {
    setIsLoading(true);
    try {
      let { data } = await searchGoiThau({
        pageIndex: 1,
        pageSize: 10000
      });
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        setListGoiThau(data?.data?.content);
        setConfigTable({
          totalPages: data?.data?.pageable?.totalPages,
          totalElements: data?.data?.pageable?.totalElements,
          numberOfElements: data?.data?.pageable?.pageNumber,
        })
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
    }
  };
  const handlerGetDetailGoiThau = async (id?: any) => {
    setIsLoading(true);
    try {
      let { data } = await getDetailGoiThau(id);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        setDetailGoiThau(data?.data)
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
    }
  };

  const handlerOpenDialogGoiThau = () => {
    setOpenDialogThemMoi(true);
    setDetailGoiThau({});
  }

  const getListVatTu = async (id: any) => {
    setIsLoading(true);
    try {
      let { data } = await searchVT({
        bidId: id || detailGoiThau?.id,
      });
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        setDsVatTu(data?.data?.content);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
    }
  };
  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center ">
        <>
          <CustomIconButton
            variant="edit"
            onClick={() => {
              handlerGetDetailGoiThau(props.cell.row.original?.id)
              // setItemSelected(props.cell.row.original);
              setOpenDialogThemMoi(true);
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>

          {/* <CustomIconButton
            variant="delete"
            onClick={() => {
            }}
          >
            <i className="bi bi-trash3-fill text-danger"></i>
          </CustomIconButton> */}
        </>
      </div>
    )
  };

  const ListGoiThauColumns: ReadonlyArray<Column<ListGoiThauInterface>> = [
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
        <div className="text-center">
          <i className="bi bi-circle-fill" style={{ color: props.data[props.row.index]?.color }}></i>
        </div>
      )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "createdDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index].createdDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Số quyết định"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "decisionNumber",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={props.data[props.row.index].decisionNumber}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Ngày quyết định"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "decisionDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.decisionDate)}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Hiệu lực bắt đầu"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "effectiveDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.effectiveDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Hiệu lực kết thúc"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "untilDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.untilDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Loại phiếu"
          className=" text-center text-white align-middle bg-pri spaces min-w-90"
        />
      ),
      id: "formTypeName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.formTypeName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Loại thầu"
          className="text-center text-white align-middle bg-pri spaces min-w-90"
        />
      ),
      id: "bidTypeName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.bidTypeName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Tổng thành tiền"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "thanhTien",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.thanhTien)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Tổng thuốc/vật tư"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "tongThuocVatTu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.tongThuocVatTu)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Tổng nhà cung cấp"
          className="text-center text-white align-middle bg-pri spaces min-w-130"
        />
      ),
      id: "tongNhaCungCap",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.tongNhaCungCap)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ListGoiThauInterface>
          tableProps={props}
          title="Ghi chú"
          className="text-center text-white align-middle bg-pri spaces min-w-200"
        />
      ),
      id: "note",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.note}
        />
      ),
    },
  ];

  const ListVatTuColumns: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="STT"
          className="text-center text-white align-middle bg-pri spaces min-w-50"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={props.row.index + 1}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Mã vật tư"
          className="text-center text-white align-middle bg-pri spaces min-w-100"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center  s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Tên vật tư"
          className="text-center text-white align-middle bg-pri spaces min-w-220"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Đơn vị"
          className="text-center text-white align-middle bg-pri spaces min-w-80"
        />
      ),
      id: "UoM",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.UoM}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Số lượng"
          className="text-center text-white align-middle bg-pri spaces min-w-80"
        />
      ),
      id: "quantity",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.quantity)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="SL nhập kho"
          className="text-center text-white align-middle bg-pri spaces min-w-100"
        />
      ),
      id: "batchNum",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={props.data[props.row.index]?.batchNum}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="SL còn lại"
          className=" text-center text-white align-middle bg-pri spaces min-w-100"
        />
      ),
      id: "remainingQuantity",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.remainingQuantity)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Giá thầu"
          className="text-center text-white align-middle bg-pri spaces min-w-100"
        />
      ),
      id: "bidPrice",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.bidPrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Thành tiền"
          className="text-center text-white align-middle bg-pri spaces min-w-100"
        />
      ),
      id: "totalAmount",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.totalAmount * props.data[props.row.index]?.giaThau)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Số đăng ký"
          className="text-center text-white align-middle bg-pri spaces min-w-120"
        />
      ),
      id: "regisNum",
      Cell: ({ ...props }) => (
        <TableCustomCell
        className="text-center s-2"
          data={props.data[props.row.index]?.regisNum}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Gói thầu"
          className="text-center text-white align-middle bg-pri spaces min-w-130"
        />
      ),
      id: "bidPackage",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={formatMoney(props.data[props.row.index]?.bidPackage)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Nhóm thầu"
          className="text-center text-white align-middle bg-pri spaces min-w-200"
        />
      ),
      id: "bidGroup",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.bidGroup}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="STT thầu"
          className="text-center text-white align-middle bg-pri spaces min-w-200"
        />
      ),
      id: "bidserNum",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.bidserNum}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="Nhà cung cấp"
          className="text-center text-white align-middle bg-pri spaces min-w-200"
        />
      ),
      id: "suppName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.suppName}
        />
      ),
    },
  ];
  return (
    <div className="bg-white">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-20 mr-5 bg-white h-calc-vh-54 radius-2">
            <SelectTree
              className="w-100 h-100 border  px-0  border-0 overflow-auto"
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              selectTree={treeData}
            />
          </div>
          <div className="spaces width-80 bg-gray radius-2">
            <div className="flex justify-content-between bg-white  spaces width-100 ">
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0  px-10">
                Danh sách gói thầu
              </h4>
              <div className=" bg-white flex align-items-center ">
                <Button
                  className="btn-fill mx-2 mr-10 spaces min-w-90px btn btn-primary"
                  onClick={handlerOpenDialogGoiThau}
                >
                  <i className="bi bi-plus-lg text-pri fs-3"></i>
                  Thêm
                </Button>
              </div>
            </div>
            <div className="d-flex  mt-5 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className="flex-grow-1 ">
                    <InputSearch
                      placeholder="Tìm kiếm"
                      handleChange={() => { }}
                      className="spaces h-31 pr-4  radius-3"
                    />
                  </div>
                </div>
                <div className=" spaces bg-white mb-0 mt-6">
                  <TableCustom<any>
                    className="spaces h-bang-ds-thau"
                    data={listGoiThau || []}
                    columnsActions={columnsActions}
                    columns={ListGoiThauColumns}
                    verticalScroll={true}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                    getSelectedRowsData={handleSelectData}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    className="border border-0"
                  />
                </div>
              </div>
            </div>

            <div className="bg-white spaces width-100 mt-5">
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0  px-10">
                Danh sách vật tư
              </h4>
            </div>
            <div className="d-flex  mt-1 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className="flex-grow-1 ">
                    <InputSearch
                      placeholder="Tìm kiếm"
                      handleChange={() => { }}
                      className="spaces h-31 pr-4 radius-3"
                    />
                  </div>
                </div>
                <div className=" spaces bg-white mb-0 mt-6">
                  <TableCustom<any>
                    className="spaces h-bang-vt-thau"
                    data={dsVatTu || []}
                    columns={ListVatTuColumns}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                    verticalScroll={true}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPageVT}
                    rowsForPage={rowsForPage}
                    page={pageVT}
                    setPage={setPageVT}
                    setRowsPerPage={setRowsPerPageVT}
                    totalPages={configTableVT.totalPages}
                    totalElements={configTableVT.totalElements}
                    numberOfElements={configTableVT.numberOfElements}
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDialogThemMoi && (
        <DialogThemMoiGoiThau
          show={openDialogThemMoi}
          onHide={handleCloseDialog}
          initialValues={{ goiThau: detailGoiThau }}
        />
      )}
    </div>
  );
};

export default FormQuanLyThau;
