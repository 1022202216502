import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../appContext/AppContext";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTMLNoValid";
import { generateForm } from "../../../../utils/AppFunction";
import { PhieuXuat } from "../../../models/XuatKhoModel";

type Props = {
  modelID?: any,
  warehouseExportType?: any
};

const ThongTinPhieu = ({
  modelID,
  warehouseExportType
}: Props) => {

  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    handleChange
  } = useFormikContext<PhieuXuat>();
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [isView, setIsView] = useState<any>(false);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    getListform();
  }, [warehouseExportType.modelId]);

  const getListform = async () => {
    let data = await generateForm(warehouseExportType?.modelId || modelID, setIsLoading)
    setListJsonGenerate(data)
  }
  
  return (
    <div className="bg-white spaces mb-1 pb-15 fl-1 pl-16 pr-16">
      <GenerateFormHTML
        isView={isView}
        listFieldAuto={listJsonGenerate}
        itemEdit={data}
        isSave={false}
        isUpdate={true}
        modelID={modelID}
        handleSubmit={handleSubmit}
        setValues={setValues}
        setTouched={setTouched}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
        isValid={isValid}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ThongTinPhieu;
