import QuanLyKho from "../component/quan-ly-kho/QuanLyKho";
import PhanHeDieuChuyen from "../phan-he-dieu-chuyen/PhanHeDieuChuyen";
import PhanHeNhapKho from "../phan-he-nhap-kho/PhanHeNhapKho";
import PhanHeXuatKho from "../phan-he-xuat-kho/PhanHeXuatKho";

export const danhSachTabKhoVatTu = [
    {
        eventKey: "0",
        title: "Xem tồn kho",
        component: <QuanLyKho warehouseId={2} />,
    },
    {
        eventKey: "1",
        title: "Nhập kho",
        component: <PhanHeNhapKho warehouseId={2}/>,
    },
    {
        eventKey: "2",
        title: "Điều chuyển",
        component: <PhanHeDieuChuyen warehouseId={2}/>,
    },
    {
        eventKey: "3",
        title: "Xuất kho",
        component: <PhanHeXuatKho warehouseId={2}/>,
    },
];

export {};