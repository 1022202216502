import { useEffect, useState } from "react";
import { Button, FormCheck, InputGroup } from "react-bootstrap";
import Autocomplete from "../AutocompleteObjectV2";
import MenuCustom from "../menu-custom/MenuCustom";
import TextValidator from "../TextValidator";
import "./filterSidebar.scss";
import { Form, Formik } from "formik";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";

export const TYPE_SEARCH = {
  TODAY: "TODAY",
  CURR_WEEK: "CURR_WEEK",
  CURR_MONTH: "CURR_MONTH",
  YESTERDAT: "YESTERDAT",
  LAST_SEVEN_DAYS: "LAST_SEVEN_DAY",
  LAST_THIRTY_DAYS: "LAST_THIRTY_DAY",
  NEXT_THREE_DAYS: "NEXT_THREE_DAYS",
  NEXT_SEVEN_DAYS: "NEXT_SEVEN_DAYS"
};

export const PATIENT_TYPE = {
  BHYT: {
    code: "BHYT",
    name: "BHYT",
  },
  VIEN_PHI: {
    code: "VP",
    name: "Viện phí",
  },
  YEU_CAU: {
    code: "YC",
    name: "Yêu cầu",
  },
  KHAM_DOAN: {
    code: "PE",
    name: "Khám đoàn",
  },
  MIEN_PHI: {
    code: "MP",
    name: "Miễn phí",
  },
};
const TYPE_CHECKS = [
  { label: "BHYT", type: PATIENT_TYPE.BHYT.code },
  { label: "Viện phí", type: PATIENT_TYPE.VIEN_PHI.code },
  { label: "Miễn phí", type: PATIENT_TYPE.MIEN_PHI.code },
  { label: "Yêu cầu", type: PATIENT_TYPE.YEU_CAU.code },
];
const LIST_ORDER = [
  {
    id: 1,
    value: "ASCENDING",
    name: "Tăng dần"
  },
  {
    id: 2,
    value: "DERCEASE",
    name: "Giảm dần"
  }
];

const LIST_PERIOD = [
  { type: TYPE_SEARCH.TODAY, name: "Trong Ngày" },
  { type: TYPE_SEARCH.CURR_WEEK, name: "Trong Tuần" },
  { type: TYPE_SEARCH.CURR_MONTH, name: "Trong Tháng" },
  { type: null, name: "" },
  { type: TYPE_SEARCH.YESTERDAT, name: "Hôm Qua" },
  { type: TYPE_SEARCH.LAST_SEVEN_DAYS, name: "7 Ngày Gần đây" },
  { type: TYPE_SEARCH.LAST_THIRTY_DAYS, name: "30 Ngày Gần đây" },
  { type: null, name: "" },
  { type: TYPE_SEARCH.NEXT_THREE_DAYS, name: "3 Ngày Tới" },
  { type: TYPE_SEARCH.NEXT_SEVEN_DAYS, name: "7 Ngày Tới" }
  // { type: null, name: "" },
  // { type: null, name: "Tìm Kiếm Theo Mã Bệnh Nhân" },
  // { type: null, name: "" },
  // { type: null, name: "(Tìm Kiếm Nâng Cao)" },
];
type Props = {
  title?: string;
  formalList?: any[]; // list hình thức
  formCheckList?: any[]; // list formcheck
  defaultValueFormCheckList?: any;
  onSearch: any;
  typeCheckBHYT?: any;
  typeCheckVP?: any;
  setTypeCheckBHYT?: any;
  setTypeCheckVP?: any;
  isFilterByType?: boolean;
  listMenuItem?: any[];
  handleSelectOption?: any;
  formalDefault?: any;
  showMenuItem?: boolean;
  showSort?: boolean;
};

const FilterSidebar = (props: Props) => {
  const {
    title = "",
    formalList = LIST_ORDER,
    formCheckList = [],
    onSearch,
    isFilterByType,
    listMenuItem,
    handleSelectOption,
    formalDefault,
    showMenuItem = false,
    showSort = false,
    defaultValueFormCheckList,
  } = props;
  const [keyword, setKeyword] = useState<any>(null);
  const [formal, setFormal] = useState<any>(formalDefault || null);
  const [order, setOrder] = useState<any>(LIST_ORDER[1]);
  const [period, setPeriod] = useState<any>(null);
  const [typeChecks, setTypeChecks] = useState<any[]>([]);
  const [typeRadio, setTypeRadio] = useState<any>(defaultValueFormCheckList);

  useEffect(() => {
    onSearch({ keyword, formal, order, period, typeChecks, typeRadio });
  }, [order, formal, period, typeChecks, typeRadio]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch({
        keyword,
        formal,
        order,
        period,
        typeChecks,
        typeRadio
      });
    }
  };

  const handleTypeSearch = (type: string) => {
    let fromDate = new Date();
    let toDate = new Date();

    switch (type) {
      case TYPE_SEARCH.TODAY:
        break;
      case TYPE_SEARCH.CURR_WEEK:
        const dayOfWeek = fromDate.getDay();
        fromDate.setDate(
          fromDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
        );
        toDate.setDate(
          toDate.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek)
        );
        break;
      case TYPE_SEARCH.CURR_MONTH:
        fromDate.setDate(1);
        toDate.setMonth(toDate.getMonth() + 1);
        toDate.setDate(0);
        break;
      case TYPE_SEARCH.YESTERDAT:
        fromDate.setDate(fromDate.getDate() - 1);
        toDate.setDate(toDate.getDate() - 1);
        break;
      case TYPE_SEARCH.LAST_SEVEN_DAYS:
        fromDate.setDate(fromDate.getDate() - 6);
        break;
      case TYPE_SEARCH.LAST_THIRTY_DAYS:
        fromDate.setDate(fromDate.getDate() - 29);
        break;
      case TYPE_SEARCH.NEXT_THREE_DAYS:
        fromDate.setDate(fromDate.getDate() + 1);
        toDate.setDate(toDate.getDate() + 3);
        break;
      case TYPE_SEARCH.NEXT_SEVEN_DAYS:
        fromDate.setDate(fromDate.getDate() + 1);
        toDate.setDate(toDate.getDate() + 7);
        break;
      default:
        break;
    }

    setPeriod({
      fromDate: fromDate,
      toDate: toDate
    });
  };

  useEffect(() => {
    if (keyword?.trim() === "") {
      onSearch({
        keyword,
        formal,
        order,
        period,
        typeChecks,
        typeRadio
      });
    }
  }, [keyword]);

  const handleTypeCheckChange = (type: string, checked: boolean) => {
    if (checked) {
      setTypeChecks((prev) => [...prev, type]);
    } else {
      setTypeChecks((prev) => prev.filter((t) => t !== type));
    }
  };

  const handleTypeRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTypeRadio(e.target.value)
  };

  return (
    <>
      <div className="d-flex justify-content-between spaces px-12">
        <h4 className="text-title fw-bold fs-4 spaces mb-0">{title}</h4>
        {showMenuItem && (
          <MenuCustom
            handleSelectOption={handleSelectOption}
            listMenuItem={listMenuItem}
            menuLabel={
              <div className="cursor-pointer">
                <i className="bi bi-list text-title fs-1"></i>
              </div>
            }
          />
        )}
      </div>
      {showSort && (
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <div className="d-flex spaces px-12 gap-5 pb-10">
              <h4 className="fw-bold fs-4 spaces W-80 mb-0">Sắp xếp</h4>
              <div className="d-flex align-items-center flex-grow-1">
                <Autocomplete
                  options={formalList}
                  value={formal}
                  name="formal"
                  onChange={(selectedOption) => {
                    setFormal(selectedOption);
                  }}
                  className="radius spaces w-100 h-29 pe-2"
                  placeholder="Chọn hình thức"
                />
                <Autocomplete
                  options={LIST_ORDER}
                  value={order}
                  name="formal"
                  onChange={(selectedOption) => {
                    setOrder(selectedOption);
                  }}
                  className="radius spaces w-100 h-29"
                />
              </div>
            </div>
          </Form>
        </Formik>
      )}
      {isFilterByType && (
        <div className="d-flex spaces px-12 gap-10">
          {TYPE_CHECKS.map((check, index) => (
            <FormCheck
              key={check.type}
              type="checkbox"
              label={check.label}
              className="d-flex align-items-center spaces gap-7"
              checked={typeChecks.includes(check.type)}
              onChange={(e) =>
                handleTypeCheckChange(check.type, e.target.checked)
              }
            />
          ))}
        </div>
      )}
      <div className="d-flex spaces px-12 gap-10">
        <div className="flex-auto w-100">
          <InputGroup className="spaces h-29 px-0 flex-nowrap">
            <TextValidator
              className="spaces h-29 min-w-177 w-100 "
              name="keyword"
              placeholder="Tìm kiếm"
              value={keyword}
              onChange={(e: any) => {
                setKeyword(e?.target?.value);
              }}
              onKeyDown={handleKeyPress}
            />
            <Button
              className="btn-fill spaces min-w-105 h-29 rounded-end-1 dropdown-toggle z-index-1055"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Tìm kiếm</span>
              <ul className="dropdown-menu filter-dropdown">
                {LIST_PERIOD.map(
                  (
                    item: {
                      type: string | null;
                      name: string;
                    },
                    index: number
                  ) => {
                    return !!item.name.trim() ? (
                      <li
                        key={index}
                        className="dropdown-item"
                        onClick={() => item.type && handleTypeSearch(item.type)}
                      >
                        {item.name}
                      </li>
                    ) : (
                      <li key={index} className="dropdown-divider"></li>
                    );
                  }
                )}
              </ul>
            </Button>
          </InputGroup>
        </div>
      </div>
      {formCheckList.length && (
        <div className="d-flex spaces px-12 py-4 gap-10">
          {formCheckList.map((item) => (
            <FormCheck
              key={item.type}
              type="radio"
              label={item.label}
              value={item.value}
              className="d-flex align-items-center spaces gap-7"
              checked={typeRadio === item.value}
              onChange={(e) => handleTypeRadioChange(e)}
            />
          ))}
        </div>
      )}
    </>
  );
};
export default FilterSidebar;
