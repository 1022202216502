import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";
import { DanhSachPhieuTuTrucInterface } from "../PhanHeTuTrucModel";
import { Column } from "react-table";

export const DanhSachPhieuColumns: ReadonlyArray<
  Column<DanhSachPhieuTuTrucInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Loại tủ trực"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "loaiTuTruc",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.cabinetTypeName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Loại phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "loaiPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.loaiPhieu}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "ngayTao",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={props.data[props.row.index]?.ngayTao}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày y lệnh"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "ngaYLenh",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.doExecution)}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Khoa nhập phiếu"
          className=" text-center text-white align-middle bg-pri min-w-40px"
        />
      ),
      id: "khoaNhapPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={formatMoney(props.data[props.row.index]?.whIssueName)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Nơi thực hiện"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "noiThucHien",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.noiThucHien}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ghi chú"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "notes",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.notes}
        />
      ),
    },
  ];

  export const BSCoSoTuTrucColumns: ReadonlyArray<
  Column<DanhSachPhieuTuTrucInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    // {
    //   Header: (props) => (
    //     <TableCustomHeader<DanhSachPhieuTuTrucInterface>
    //       tableProps={props}
    //       title="Trạng thái"
    //       className="text-center text-white align-middle bg-pri min-w-80px"
    //     />
    //   ),
    //   id: "trangThai",
    //   Cell: ({ ...props }) => (
    //     <TableCustomCell
    //       className="text-left s-2"
    //       data={props.data[props.row.index]?.cabinetTypeName}
    //     />
    //   ),
    // },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "createdDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.createdDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Loại phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "loaiPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.loaiPhieu}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày nhập xuất"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "entryDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.entryDate)}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Nơi lập phiếu"
          className=" text-center text-white align-middle bg-pri min-w-40px"
        />
      ),
      id: "noiLapPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={formatMoney(props.data[props.row.index]?.whIssueName)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Nơi thực hiện"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "noiThucHien",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.noiThucHien}
        />
      ),
    },
  ];

  export const TMCoSoTuTrucColumns: ReadonlyArray<
  Column<DanhSachPhieuTuTrucInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Mã thuốc"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "maThuoc",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Tên Thuốc"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "tenThuoc",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={props.data[props.row.index]?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Số lượng"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "soLuong",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.totalQuantity}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Đơn vị"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "donVi",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.unitOfMeasureName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Tồn Kho"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "tonKho",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.iventory}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Giá bán"
          className=" text-center text-white align-middle bg-pri min-w-40px"
        />
      ),
      id: "giaBan",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={formatMoney(props.data[props.row.index]?.sellingPrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="% VAT"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "vat",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.vat}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Thành tiền"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "thanhTien",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.totalAmount}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Số lô"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "soLo",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.consignment}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Hạn sử dụng"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "hanSuDung",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.expiryDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Tồn kho tủ trực"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "tonKhoTuTruc",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.iventory}
        />
      ),
    },
  ];
