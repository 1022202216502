import { IconCabinet, IconHome, IconMenu } from "../../component/IconSvg"

export const dsVatTuColumnsSimple: any[] = [
  {
    name: "",
    field: "checkbox",
    width: 50,
    minWidth: 27,
  },  
  {
    name: "Mã thuốc",
    field: "code",
    width: 100,
    minWidth: 75,
    textAlign: "center"
  },
  {
    name: "Tên thuốc",
    field: "name",
    width: 400,
    minWidth: 200,
    textAlign: "left"
  },
  {
    name: "DVT",
    field: "dvt",
    width: 100,
    minWidth: 50,
    textAlign: "center"
  },
  {
    name: "Nhà cung cấp",
    field: "nhaCungCap",
    width: 200,
    minWidth: 75,
    textAlign: "left",
  },
  {
    name: "Hãng sản xuất",
    field: "hangSanXuat",
    width: 300,
    minWidth: 80,
    textAlign: "left",
  },
  {
    name: "Nước sản xuất",
    field: "nuocSanXuat",
    width: 200,
    minWidth: 80,
    textAlign: "left",
  },
  {
    name: "Số đăng ký",
    field: "soDangKy",
    width: 180,
    minWidth: 70,
    textAlign: "left",
  },
  {
    name: "Số lô nhập",
    field: "soLoNhap",
    width: 150,
    minWidth: 85,
    textAlign: "left",
  },
];

export const TreeVatTu = {
  code: "all",
  name: "Tất cả dịch vụ",
  icon: <IconMenu />,
  filter: [
    {
      code: "all.khamBenh",
      name: "Loại thuốc",
    },
    {
      code: "Nhóm BHYT",
      name: "Xét nghiệm",
    },
  ],
};

export const COMPONENT_TYPE = {
  TEXTFIELD: "textfield",
  DATEPICKER: "datepicker",
  NUMBER: "number",
  SELECT: "select",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  TEXTAREA: "textarea",
  FILE: "file",
  COLUMNS: "columns",
  BUTTON: "button",
  PASSWORD: "password",
  SELECTBOXES: "selectboxes",
  DAY: "day",
  TIME: "time",
  EMAIL: "email",
  DATETIME: "datetime",
  PHONE: "phoneNumber",
  CONTENT: "content",
  HTML: "htmlelement"
};