import React, { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../appContext/AppContext";
import { Tab, Tabs } from "react-bootstrap";
import { tab } from "../appContext/AppContextModel";
import { localStorageItem } from "../utils/LocalStorage";

type TabMenuProps = {
  danhsachTabs: tab[];
  handlerSelect?: (value: any) => void;
  keyDanhSachTabs?: string;
}

export const TabMenu: FC<TabMenuProps> = (props) => {
  const { danhsachTabs, keyDanhSachTabs, handlerSelect } = props;
  let data = localStorageItem.get(keyDanhSachTabs) || [];
  const [activeTab, setActiveTab] = useState<string>("0");
  const [tabs, setTabs] = useState<tab[]>([]);

  useEffect(() => {
    setTabs(danhsachTabs);
  }, [danhsachTabs])

  const handleTabSelect: (eventKey: string | null) => void = (eventKey) => {
    if (eventKey) {
      handlerSelect && handlerSelect(eventKey || "");
      setActiveTab(eventKey);
    }
  };

  return (
    <Tabs
      className="tabs nav nav-tabs customs-tabs"
      activeKey={activeTab}
      onSelect={handleTabSelect}
    >
      {tabs.map((item, index) => {
        return (
          <Tab
            className="tab"
            eventKey={index}
            key={item.eventKey}
            title={
              <div className="label spaces pr-10">
                <span>{item?.title}</span>
              </div>
              // <div className={`${isCloseTab ? "spaces pr-10" : ""} label ${isDisabled ? "disabled-tab" : ""}`}>
              //   <span>{item?.title}</span>
              // </div>
            }
          >
            {item.component}
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default TabMenu;
