import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { RESPONSE_STATUS_CODE } from "../../auth/core/_consts";
import DialogConfirmDelete from "../../component/dialog-confirm-delete/DialogConfirmDelete";
import GenerateFormComponent from "../../component/generate-form/GenerateFormComponent";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { BOOLEAN_TYPE_IN_NUMBER, CODE_GENERATE_FORM, CODE_SUCCESS, ERROR_MESSAGE } from "../../utils/Constant";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { IDepartment } from "../models/ModelKhoaPhong";
import { ILoaiDichVu_QuanTriHeThong } from "../models/ModelLoaiDichVu";
import { createLoaiDichVuQuanTriHeThong, getJsonGenerateForm, searchLoaiDichVuQuanTriHeThong, updateLoaiDichVuQuanTriHeThong } from "./LoaiDichVuServices";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { generateForm } from "../../utils/AppFunction";

type Props = {};

const LoaiDichVuTable = (props: Props) => {
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] = useState(false);
  const [danhSachLoaiDichVu, setDanhSachLoaiDichVu] = useState<ILoaiDichVu_QuanTriHeThong[]>([]);
  const [loaiDichVu, setLoaiDichVu] = useState<any>(null);
  const [reloadData, setReloadData] = useState(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalElements, setTotalElements] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(10);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>({});
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const { setIsLoading } = useContext(AppContext);
  useEffect(() => {
    searchData();
  }, []);

  useEffect(() => {
    getDanhSachKhoa(pageIndex, pageSize);
  }, [reloadData, pageSize, pageIndex]);
  const searchData = async () => {
    try {
      let response = await searchLoaiDichVuQuanTriHeThong({});
      if (response?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDanhSachLoaiDichVu(response?.data?.data);
      } else {
        toast.error(response?.data?.messages);
      }
    } catch (error) {
    }
  };
  const getDanhSachKhoa = async (page: number, rowPerPage: number) => {
    // try {
    //   const result = await getListDepartment(page, rowPerPage)

    //   if (result?.data?.code === CODE_SUCCESS) {
    //     setDanhSachKhoa(result?.data?.data?.content)
    //     setTotalElements(result?.data?.data?.totalElements)
    //     setTotalPages(result?.data?.data?.totalPages)
    //   } else {
    //     toast.error(ERROR_MESSAGE)
    //   }
    // } catch (error) {
    //   toast.error(ERROR_MESSAGE)
    // }
  };
  const handleOpenDMKPDialog = async () => {
    setIsUpdate(false)
    let data = await generateForm(CODE_GENERATE_FORM.loaiDichVuQuantriHeThong, setIsLoading)
    setListJsonGenerate(data)
    setShouldOpenModal(true);
    // setIsLoading(true);
    // try {
    //   let dataFieldAuto = localStorage.getItem(`form-SVC`);
    //   if (dataFieldAuto) {
    //     setListJsonGenerate(JSON.parse(dataFieldAuto) || {});
    //     setShouldOpenModal(true);
    //     return;
    //   } else {
    //     let res = await getJsonGenerateForm("SVC");
    //     if (res?.data?.code === CODE_SUCCESS) {
    //       setShouldOpenModal(true);
    //       localStorage.setItem(`form-SVC`, res?.data?.data);
    //       setListJsonGenerate(JSON.parse(res?.data?.data));
    //     } else {
    //       toast.error(ERROR_MESSAGE);
    //     }
    //   }
    // } catch (error) {
    //   toast.error(ERROR_MESSAGE);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const handleSaveServiceType = async (data: any) => {
    try {
      const transformedData: any = {};
      for (const key in data?.dataSend) {
        if (typeof data?.dataSend[key] === 'boolean') {
          transformedData[key] = data?.dataSend[key] ? 1 : 0;
        } else {
          transformedData[key] = data?.dataSend[key];
        }
      }
      const dataBody: ILoaiDichVu_QuanTriHeThong = {
        serviceTypeId: transformedData?.serviceTypeId,
        serviceTypeCode: transformedData?.serviceTypeCode,
        orderInServiceGroup: transformedData?.orderInServiceGroup,
        serviceTypeName: transformedData?.serviceTypeName,
        modalityCDHA: transformedData?.modalityCDHA,
        numberOfCaseInDay: transformedData?.numberOfCaseInDay,
        exportMedicineBHYT: transformedData?.exportMedicineBHYT || 0,
        printBarcode: transformedData?.printBarcode || 0,
        reuiredChooseMachine: transformedData?.reuiredChooseMachine || 0,
        printBoldDrug: transformedData?.printBoldDrug || 0,
        warning: Number(transformedData?.warning),
        lock: transformedData?.lock || 0,
        collectedMoney: transformedData?.collectedMoney || 0,
        printCodeAndName: transformedData?.printCodeAndName || 0,
        noNationalPrescription: transformedData?.noNationalPrescription || 0,
        noOpenResultForm: transformedData?.noOpenResultForm || 0,
        sendResultToPACS: transformedData?.sendResultToPACS || 0,
        senOrderToPACS: transformedData?.senOrderToPACS || 0,
        showAccNumber: transformedData?.showAccNumber || 0,
        specialControlledDrug: transformedData?.specialControlledDrug || 0,
        doNotExportInvoice: transformedData?.doNotExportInvoice || 0,
      };
      let response = data?.isUpdate
        ? await updateLoaiDichVuQuanTriHeThong(data?.dataSend?.termModelId, dataBody)
        : await createLoaiDichVuQuanTriHeThong(dataBody)
      if (response?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success("Thêm mới thành công");
        handleCloseModal();
      } else {
        toast.error(response?.data?.messages);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleUpdateLoaiDichVu =async (data: ILoaiDichVu_QuanTriHeThong) => {
    let _data = await generateForm(CODE_GENERATE_FORM.loaiDichVuQuantriHeThong, setIsLoading);
    setListJsonGenerate(_data)
    const dataBody = {
      ...data,
      serviceTypeId: data?.serviceTypeId,
      serviceTypeCode: data?.serviceTypeCode,
      orderInServiceGroup: data?.orderInServiceGroup,
      serviceTypeName: data?.serviceTypeName,
      modalityCDHA: data?.modalityCDHA,
      numberOfCaseInDay: data?.numberOfCaseInDay,
      warning: String(data?.warning),
      exportMedicineBHYT: data?.exportMedicineBHYT === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      sendResultToPACS: data?.sendResultToPACS === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      printBarcode: data?.printBarcode === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      reuiredChooseMachine: data?.reuiredChooseMachine === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      noNationalPrescription: data?.noNationalPrescription === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      printBoldDrug: data?.printBoldDrug === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      lock: data?.lock === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      collectedMoney: data?.collectedMoney === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      printCodeAndName: data?.printCodeAndName === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      noOpenResultForm: data?.noOpenResultForm === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      specialControlledDrug: data?.specialControlledDrug === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      doNotExportInvoice: data?.doNotExportInvoice === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      senOrderToPACS: data?.senOrderToPACS === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
      showAccNumber: data?.showAccNumber === BOOLEAN_TYPE_IN_NUMBER.TRUE ? true : false,
    };
    setLoaiDichVu(dataBody);
    setIsUpdate(true)
    setShouldOpenModal(true);
  };

  const handleOpenConfirmDeleteDialog = (data: ILoaiDichVu_QuanTriHeThong) => {
    setShouldOpenConfirmDeleteDialog(true);
    setLoaiDichVu(data);
  };

  const handleDeleteServiceType = async () => {
    // try {
    //   const result = await deleteDepartment(idKhoa)
    //   if (result?.data?.code === CODE_SUCCESS) {
    //     setReloadData(!reloadData)
    //     toast.success(MESSAGE_SUCCESS.deleteDepartment)
    //     setShouldOpenConfirmDeleteDialog(false)
    //   } else {
    //     toast.error(ERROR_MESSAGE)
    //   }
    // } catch (error) {
    //   toast.error(ERROR_MESSAGE)
    // }
  };

  const handleCloseModal = () => {
    setReloadData(!reloadData);
    setLoaiDichVu({});
    setShouldOpenModal(false);
    searchData();
    setIsUpdate(false)
  };

  const serviceTypeColumn: ReadonlyArray<Column<ILoaiDichVu_QuanTriHeThong>> = [
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title={"ID"}
          className="col-1 text-center text-white px-3 min-w-200px"
        />
      ),
      id: "serviceTypeId",
      Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index]?.serviceTypeId} />,
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title={"Mã loại dịch vụ"}
          className="col-1 text-center text-white px-3 min-w-200px"
        />
      ),
      id: "serviceTypeCode",
      Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index]?.serviceTypeCode} />,
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title={"Tên loại dịch vụ"}
          className="col-1 text-center text-white px-3 min-w-300px"
        />
      ),
      id: "serviceTypeName",
      Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index]?.serviceTypeName} />,
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title={"STT"}
          className="col-1 text-center text-white px-3 min-w-50px"
        />
      ),
      id: "STT",
      Cell: ({ ...props }: any) => (
        <TableCustomCell
          className="text-center"
          data={Number((pageIndex - 1) * pageSize + props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title={"Cho phép xuất thuốc BHYT Ngoại trú"}
          className="col-1 text-center text-white px-3 min-w-100px"
        />
      ),
      id: "exportMedicineBHYT",
      Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].exportMedicineBHYT} />,
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title={"Cho phép gửi sang PACS"}
          className="col-1 text-center text-white px-3 min-w-100px"
        />
      ),
      id: "sendResultToPACS",
      Cell: ({ ...props }) => (
        <TableCustomCell className="text-center" data={props.data[props.row.index].sendResultToPACS} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title={"Thao tác"}
          className="col-2 text-center text-white px-3"
        />
      ),
      id: "Thao tác",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="d-flex justify-content-center"
          data={
            <>
              <CustomIconButton
                variant="edit"
                onClick={() => handleUpdateLoaiDichVu(props.data[props.row.index])}
              >
                <i className="bi bi-pencil-square text-navy"></i>
              </CustomIconButton>

              <CustomIconButton
                variant="delete"
                onClick={() => handleOpenConfirmDeleteDialog(props.data[props.row.index])}
              >
                <i className="bi bi-trash3-fill text-danger"></i>
              </CustomIconButton>
            </>
          }
        />
      ),
    },
  ];

  return (
    <Container fluid className="p-5 border bg-white">
      <Row>
        <Col>
          <Button
            className="btn-fill min-w-50px"
            onClick={handleOpenDMKPDialog}
          >
            Thêm mới
          </Button>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <TableCustom
            pageIndex={pageIndex}
            pageSize={pageSize}
            verticalScroll={true}
            data={danhSachLoaiDichVu}
            columns={serviceTypeColumn}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TablePagination
            handlePagesChange={handlePagesChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={pageSize}
            rowsForPage={rowsForPage}
            page={pageIndex}
            setPage={setPageIndex}
            setRowsPerPage={setPageSize}
            totalPages={totalPages}
            totalElements={totalElements}
            numberOfElements={totalElements}
          />
        </Col>
      </Row>

      {shouldOpenModal && (
        // <ModalLoaiDichVu
        //   department={khoa as IDepartment}
        //   setDialog={setShouldOpenModal}
        //   handleCloseDialog={handleCloseModal}
        // />
        <GenerateFormComponent
          onCloseClick={handleCloseModal}
          listFieldAuto={listJsonGenerate}
          handleSave={handleSaveServiceType}
          itemEdit={loaiDichVu}
          isUpdate={isUpdate}
        />
      )}

      {shouldOpenConfirmDeleteDialog && (
        <DialogConfirmDelete
          dialogDelete={shouldOpenConfirmDeleteDialog}
          setDialogDelete={setShouldOpenConfirmDeleteDialog}
          handleDelete={handleDeleteServiceType}
        />
      )}
    </Container>
  );
};

export default LoaiDichVuTable;
