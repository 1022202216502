import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_NHAP_KHO = API + "/slip";
const API_PATH_THONG_KE_VAT_TU = API + '/item';

export const addPhieuNhapKho = (data: any) => {
  let url = API_NHAP_KHO + `/phieuNhapKhoAdd`;
  return axios.post(url, data);
};
export const updatePhieuNhapKho = (id: string, data: any) => {
  let url = API_NHAP_KHO + `/phieuNhapKhoAdd/${id}`;
  return axios.put(url, data);
};

export const addVatTu = (data: any) => {
  let url = API + `/inventory-item`;
  return axios.post(url, data);
};
export const updateVatTu = (id: string, data: any) => {
  let url = API + `/inventory-item/${id}`;
  return axios.put(url, data);
};

export const searchPhieuNhapKho = (searchData: any) => {
  const url = `${API_NHAP_KHO}` + `/search`;
  return axios.post(url, searchData);
};

export const deletePhieuNhapKho = (id: string) => {
  const url = `${API_NHAP_KHO}` + `/${id}`;
  return axios.delete(url);
};
export const searchListVatTu = (searchObject?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/search`;
  return axios.post(url, searchObject);
};
export const getDsThuocNhapKho = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};
export const updateDsThuocNhapKho = (data: any) => {
  const url = `${API}/inventory-item/update-items`;
  return axios.put(url, data);
};
export const checkNewItem = (params: any) => {
  const url = `${API}/inventory-item/check-serial-number`;
  return axios.get(url, { params: params });
};