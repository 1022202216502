import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AppContext } from "../../appContext/AppContext";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import InputSearch from "../../component/InputSearch";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import TextField from "../../component/TextField";
import { CODE, DEFAULT_PAGE_INDEX, KEY, SELECTION_MODE, TYPE_WH } from "../../utils/Constant";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { DanhSachPhieuColumns, summaryStatus } from "../constants/ColumnsDieuChuyen";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { INIT_VALUES_NK } from "../constants/constansDieuChuyen";
import { deletePhieuDieuChuyen, searchPhieuDieuChuyen } from "../services/services";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { toast } from "react-toastify";
import { PhanHeDieuChuyenContext } from "../PhanHeDieuChuyen";
import DialogPhieuDieuChuyen from "./DialogPhieuDieuChuyen";
import "../PhanHeDieuChuyen.scss";

type Props = {
  warehouseId: number;
}

export const FormDieuChuyen: FC<Props> = (props) => {
  const { warehouseId } = props;
  const [dsPhieuDieuChuyen, setDsPhieuDieuChuyen] = useState<any[]>([]);
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<boolean>(false);
  const { setDsDieuChuyen, setOnlyView } = useContext(PhanHeDieuChuyenContext);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] =
    useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>(INIT_VALUES_NK);
  const { setIsLoading } = useContext(AppContext);
  const [searchData, setSearchData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deletePhieuDieuChuyen(itemSelected.id);
      if (CODE.SUCCESS === res.status) {
        toast.success("Xóa phiếu thành công");
        setOpenDialogConfirmDelete(false);
        updatePageData();
      }
    } catch (error: any) {
      const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
      toast.error(message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    updatePageData();
    setDsDieuChuyen([{}]);
    setOnlyView(false);
    setOpenDialogConfirmDelete(false);
    setOpenDialogThemMoi(false);
    setItemSelected(INIT_VALUES_NK);
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const { data } = await searchPhieuDieuChuyen({
        ...searchData,
        type: TYPE_WH.TRANSFER,
        warehouseId: warehouseId,
        pageSize: rowsPerPage,
        pageIndex: page,
      });
      if (CODE.SUCCESS === data.code) {
        setDsPhieuDieuChuyen(data?.data?.content || []);
        setConfigTable({
          totalPages: data?.data.totalPages,
          totalElements: data?.data.totalElements,
          numberOfElements: data?.data.numberOfElements,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, [rowsPerPage, page]);

  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className="text-center text-light min-w-80px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center ">
        <>
          <CustomIconButton
            variant="edit"
            onClick={() => {
              setItemSelected(props.cell.row.original);
              setOnlyView(true);
              setOpenDialogThemMoi(true);
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>

          <CustomIconButton
            variant="delete"
            onClick={() => {
              setItemSelected(props.cell.row.original);
              setOpenDialogConfirmDelete(true);
            }}
          >
            <i className="bi bi-trash3-fill text-danger"></i>
          </CustomIconButton>
        </>
      </div>
    ),
  };

  return (
    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex bg-white justify-content-between spaces width-100 ">
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0  px-10">
                Danh sách phiếu chuyển kho
              </h4>
              <div className=" bg-white flex align-items-center ">
                <Button
                  className="btn-fill mx-2 mr-10 spaces min-w-90px btn btn-primary"
                  onClick={() => setOpenDialogThemMoi(true)}
                >
                  <i className="bi bi-plus-lg text-pri fs-3"></i>
                  Thêm
                </Button>
              </div>
            </div>
            <div className="d-flex  mt-1 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className="d-flex spaces mx-10 ">
                    <TextField
                      label="Từ ngày"
                      name="startDate"
                      type="date"
                      value={searchData?.startDate || ""}
                      className="flex"
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="d-flex spaces mr-10">
                    <TextField
                      label="Đến ngày"
                      name="endDate"
                      type="date"
                      className="flex"
                      value={searchData?.endDate || ""}
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="flex-grow-1 ">
                    <InputSearch
                      name="keyword"
                      value={searchData?.keyword || ""}
                      placeholder="Tìm kiếm"
                      handleChange={handleChangeSearchData}
                      handleSearch={updatePageData}
                      handleKeyDown={(e: React.KeyboardEvent) =>
                        e.key === KEY.ENTER && updatePageData()
                      }
                      className="spaces h-31 pr-4  radius-3"
                    />
                  </div>
                </div>
                <div className="spaces bg-white mb-0">
                  <TableCustom<any>
                    className="spaces h-calc-vh-255"
                    data={dsPhieuDieuChuyen}
                    columns={DanhSachPhieuColumns}
                    columnsActions={columnsActions}
                    verticalScroll={true}
                    selectionMode={SELECTION_MODE.MULTI}
                    getSelectedRowsData={() => {}}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    customComponent={
                      <WfStatusSummary summaryStatus={summaryStatus} />
                    }
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
            <div className="spaces bg-white d-flex justify-content-center gap-5 mt-5 py-8">
              <Button className="btn btn-fill">
                <i className="bi bi-check-circle"></i>
                Duyệt
              </Button>
              <Button className="btn btn-fill btn-error">
                <i className="bi bi-x-circle"></i>
                Từ chối
              </Button>
            </div>
          </div>
          {openDialogThemMoi && (
            <DialogPhieuDieuChuyen
              show={openDialogThemMoi}
              onHide={handleCloseDialog}
              initialValues={{ dieuChuyen: itemSelected }}
              warehouseId={warehouseId}
            />
          )}
          {openDialogConfirmDelete && (
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handleCloseDialog}
              onYesClick={handleDelete}
              title="Xóa phiếu điều chuyển"
              message={`Bạn chắc chắn muốn xóa phiếu: ${itemSelected.code} ?`}
              yes="Xóa"
              close="Hủy"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormDieuChuyen;
