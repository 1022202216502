import React, { useContext, useEffect, useState } from "react";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTML"
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE } from "../../../../utils/Constant";
import { getDetailThongKe } from "../../../services/ThongKeServices"
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../../PhanHeThongKe"

type Props = {
  ThongTinTab?: any;
  dataVatTu?: any;
};

const TabThongTinVatTu = ({ ThongTinTab, dataVatTu }: Props) => {

  const { currentTab, currentTabChildren, setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [isView, setIsView] = useState<any>(false);
  const [data, setData] = useState<any>({});
  const { dataVatTuView } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);

  useEffect(() => {
    getListform();
    // getDetail();
  }, [dataVatTu])

  const getListform = async () => {
    let data = await generateForm(ThongTinTab?.modelId || "", setIsLoading)
    setListJsonGenerate(data)
  }
  return (
    <div className="bg-white spaces mb-6 fl-1 mx-10">
      <GenerateFormHTML
        isView={isView}
        listFieldAuto={listJsonGenerate}
        // handleSave={handlerSaveForm}
        itemEdit={dataVatTuView}
        isSave={false}
        isUpdate={true}
      />
    </div>
  );
};

export default TabThongTinVatTu;
