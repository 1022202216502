import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { IconButtonSave } from "../../component/IconSvg";
import { generateForm } from "../../utils/AppFunction";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { PhanHeDieuChuyenContext } from "../PhanHeDieuChuyen";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, TYPE_WH, WF_TRACKER } from "../../utils/Constant";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { addPhieuDieuChuyen, getDsThuocDieuChuyen, updateDsThuocDieuChuyen, updatePhieuDieuChuyen } from "../services/services";
import WfButton from "../../workflows/components/WfButton";


interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  warehouseId: number,
}

const DialogPhieuDieuChuyen = (props: Props) => {
  const { show, onHide, initialValues, warehouseId } = props;
  const { setIsLoading } = useContext(AppContext);
  const { dsDieuChuyen, setDsDieuChuyen, onlyView, setOnlyView } = useContext(PhanHeDieuChuyenContext);
  const intl = useIntl();
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);

  const validationSchema = Yup.object({
    dieuChuyen: Yup.object({
      // columnsColumns2Text3: Yup.object().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    })
  })

  const updateForm = async () => {
    const data = await generateForm("phieuDieuChuyenKhoAdd", setIsLoading);
    setListJsonGenerate(data || []);
  };

  const handleSubmit = async (values: any) => {
    const listItems = [...dsDieuChuyen].map(item => ({
      itemId: item?.item?.id,
      totalQuantity: parseFloat(item.totalQuantity),
      lstDetail: [
        {
          quantity: parseFloat(item.totalQuantity),
          conversionQuantity: parseFloat(item.totalQuantity),
          lotId: values?.dieuChuyen?.requestWhId,
          inputDate: values?.dieuChuyen?.dateCreate,
          itemId: item?.item?.id,
          itemPrice: item?.item?.purchasePrice,
        }
      ]
    }));

    const dataSubmit = {
      slipDto: {
        ...values?.dieuChuyen,
        type: TYPE_WH.TRANSFER,
        warehouseId: warehouseId,
      },
      lstItem: listItems,
    };
    try {
      setIsLoading(true);
      const { data } = values?.dieuChuyen?.id ? await updatePhieuDieuChuyen(values?.dieuChuyen?.id, dataSubmit) : await addPhieuDieuChuyen(dataSubmit);
      if (values.dieuChuyen?.id) {
        await updateDsThuocDieuChuyen({
          slipId: values.dieuChuyen?.id,
          type: TYPE_WH.TRANSFER,
          lstItem: listItems,
        });
      }
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success(((values.dieuChuyen?.id) ? "Cập nhật" : "Thêm") + " phiếu điểu chuyển thành công");
        onHide()
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getDsThuocDieuChuyen({ pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, slipId: id});
      setDsDieuChuyen(data?.data?.content || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialValues?.dieuChuyen?.id && getSelectedRowsData(initialValues?.dieuChuyen?.id);
  }, [initialValues?.dieuChuyen?.id]);

  useEffect(() => {
    updateForm();
  }, []);

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex w-100 gap-6 justify-content-between">
                <div className="d-flex gap-8">
                  <div
                    className="d-flex align-items-center title-back gap-3 cursor-pointer"
                    onClick={onHide}
                  >
                    <i className="spaces bi bi-chevron-left ml-10"></i>
                    <div className="spaces mr-20 my-3">Quay lại</div>
                  </div>
                  <div className="spaces mt-4 title">Phiếu chuyển</div>
                </div>
                <div className="d-flex gap-3">
                  <WfButton
                    tracker={WF_TRACKER.DIEU_CHUYEN}
                    objectId={values.dieuChuyen?.id}
                  />
                  <Button className="btn btn-outline">
                  <i className="bi bi-printer"></i>
                    In phiếu
                  </Button>
                  {onlyView &&
                    <Button
                      className="btn btn-fill"
                      onClick={() => setOnlyView(false)}
                    >
                      <i className="bi bi-pencil-square"></i>
                      Sửa
                    </Button>
                  }
                  {!onlyView &&
                    <Button type="submit" className="btn btn-fill">
                      <IconButtonSave />
                      Lưu
                    </Button>
                  }
                </div>
              </div>
              
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10">
                <h4 className="text-title fw-bold fs-5 spaces mb-5">Thông tin chung</h4>
                <GenerateFormComponent
                  listFieldAuto={listJsonGenerate}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  modelID={"dieuChuyen"}
                  customComponent={DynamicComponent}
                  isView={onlyView}
                />
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
      <div className="bg-white d-flex justify-content-between spaces px-10 py-12 mt-5">
        <div className="title-summary">Tổng số lượng vật tư: <span className="quantity">{dsDieuChuyen.length}</span></div>
        <div className="title-summary color-summary">Tổng tiền: <span className="money">0</span></div>
      </div>
    </Modal>
  );
};

export default DialogPhieuDieuChuyen;
