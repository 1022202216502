import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { CODE, DEFAULT_PAGE_INDEX, KEY, SELECTION_MODE } from "../../utils/Constant";
import { Button } from "react-bootstrap";
import TextField from "../../component/TextField";
import InputSearch from "../../component/InputSearch";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { LIST_MENU_TT, summaryStatus } from "../constants/TuTrucConstans";
import { TablePagination } from "../../component/table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { BSCoSoTuTrucColumns, DanhSachPhieuColumns } from "../constants/TuTrucColumns";
import DialogThemPhieuTuTruc from "./DialogThemPhieuTuTruc";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { PhanHeTuTrucContext } from "../PhanHeTuTruc";
import { deletePhieuTuTruc, searchPhieuTuTruc } from "../services/TuTrucServices";
import { toast } from "react-toastify";

type Props = {
  modelId: any;
}

export const BoSungCoSoTuTruc: FC<Props> = (props) => {
  const { modelId } = props;
  const { setIsLoading } = useContext(AppContext);
  const { tuTrucCurrent ,setDsVatTu } = useContext(PhanHeTuTrucContext);
  const [dsPhieuTuTruc, setDsPhieuTuTruc] = useState([]);
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>({});
  const [searchData, setSearchData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });

  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const { data } = await searchPhieuTuTruc({
        ...searchData,
        pageIndex: page,
        pageSize: rowsPerPage,
        type: modelId.type,
        warehouseId: !modelId.title ? null : (tuTrucCurrent as any)?.id ?? 0,
      });
      if (CODE.SUCCESS === data.code) {
        setDsPhieuTuTruc(data?.data?.content);
        setConfigTable({
          totalPages: data.data.totalPages,
          totalElements: data.data.totalElements,
          numberOfElements: data.data.numberOfElements,
        });
      } else {
        toast.error('Lỗi tìm kiếm phiếu, vui lòng thử lại');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  },[page, rowsPerPage, modelId.type, tuTrucCurrent]);

  const handleCloseDialog = () => {
    setOpenDialogThemMoi(false);
    setOpenDialogConfirmDelete(false);
    setItemSelected({});
    setDsVatTu([]);
    updatePageData();
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deletePhieuTuTruc(itemSelected.id);
      if (CODE.SUCCESS === response.status) {
        toast.success(`Xóa ${modelId.title} thành công`);
        handleCloseDialog();
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center ">
        <>
          <CustomIconButton
            variant="edit"
            onClick={() => {
              setItemSelected(props.cell.row.original);
              setOpenDialogThemMoi(true);
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>

          {modelId.title && <CustomIconButton
            variant="delete"
            onClick={() => {
              setItemSelected(props.cell.row.original);
              setOpenDialogConfirmDelete(true);
            }}
          >
            <i className="bi bi-trash3-fill text-danger"></i>
          </CustomIconButton>
          }
        </>
      </div>
    )
  };

  return (
    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex justify-content-between bg-white spaces w-100 pt-6">
                <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10 custom-title">
                  {`Danh sách ${modelId.title}`}
                </h4>
            </div>
            <div className="d-flex mt-1 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className=" bg-white flex align-items-center ">
                    {modelId.title &&
                      <Button className="btn-fill mx-2 ml-10 spaces min-w-90px btn btn-primary" onClick={() => setOpenDialogThemMoi(true)}>
                        <i className='bi bi-plus-lg text-pri fs-3'></i>
                        Thêm
                      </Button>
                    }
                  </div>
                  <div className="d-flex spaces mx-10 ">
                    <TextField
                      label="Từ ngày"
                      name="startDate"
                      type="date"
                      value={searchData?.startDate || ''}
                      className="flex"
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="d-flex spaces mr-10">
                    <TextField
                      label="Đến ngày"
                      name="endDate"
                      type="date"
                      className="flex"
                      value={searchData?.endDate || ''}
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="flex-grow-1 ">
                    <InputSearch
                      name="keyword"
                      value={searchData?.keyword || ''}
                      placeholder="Tìm kiếm"
                      handleChange={handleChangeSearchData}
                      handleSearch={updatePageData}
                      handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && updatePageData()}
                      className="spaces h-31 pr-4  radius-3"
                    />
                  </div>
                </div>
                <div className=" spaces bg-white mb-0">
                  <TableCustom<any>
                    className="spaces h-calc-vh-210"
                    data={dsPhieuTuTruc}
                    columns={BSCoSoTuTrucColumns}
                    columnsActions={columnsActions}
                    verticalScroll={true}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    customComponent={<WfStatusSummary hiddenQuantity summaryStatus={summaryStatus} col={3}/>}
                    customComponentClassName="min-w-600px"
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
          </div>
          {openDialogThemMoi &&
            <DialogThemPhieuTuTruc
              show={openDialogThemMoi}
              onHide={handleCloseDialog}
              initialValues={{ [modelId.id]: itemSelected, whReceive: tuTrucCurrent }}
              modelId={modelId}
            />
          }
          {openDialogConfirmDelete &&
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handleCloseDialog}
              onYesClick={handleDelete}
              title="Xóa phiếu"
              message={`Bạn chắc chắn muốn xóa phiếu: ${itemSelected.code} ?`}
              yes="Xóa"
              close="Hủy"
            />
          }
        </div>
      </div>
    </div>
  );
};

export default BoSungCoSoTuTruc;
