import { useContext, useEffect, useState } from "react";
import { OctTable } from "@oceantech/oceantech-ui";
import TextField from "../../component/TextField";
import { Button } from "react-bootstrap";
import AutocompleteObjectV2 from "../../component/AutocompleteObjectV2";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";
import { searchListVatTu } from "../services/TuTrucServices";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../utils/Constant";
import { AppContext } from "../../appContext/AppContext";
import { PhanHeTuTrucContext } from "../PhanHeTuTruc";

interface Props {
  isView: boolean;
}

const TableVTTuTruc = (props: Props) => {
  const { isView } = props;
  const { setIsLoading } = useContext(AppContext);
  const { dsVatTu, setDsVatTu } = useContext(PhanHeTuTrucContext);
  const [listVatTu, setListVatTu] = useState<any[]>();

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchListVatTu({
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
      });
      setListVatTu(data?.data?.content);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const data = [...dsVatTu];
    if (data[index]) {
      data[index] = {
        ...data[index],
        [name]: value,
      };
      setDsVatTu(data);
    }
  };

  const handleChangeSelect = (options: any, index: number) => {
    const data = [...dsVatTu];
    data[index] = options ? { item: options } : {};
    setDsVatTu(data);
  };

  const handleAddRow = () => {
    const data = [...dsVatTu];
    const newData = data.concat([{}]);
    setDsVatTu(newData);
  };

  const hanldeDeleteRow = (index: number) => {
    const data = [...dsVatTu].filter((_: any, i: number) => i !== index);
    setDsVatTu(data);
  };

  const columns = [
    ...(!isView
      ? [
          {
            name: "",
            field: "",
            headerStyle: {
              minWidth: 50,
              maxWidth: 50,
            },
            cellStyle: {
              textAlign: "center",
            },
            render: (row: any, index: number, stt: number) => (
              <i
                onClick={(e) => hanldeDeleteRow(index)}
                className="bi bi-trash3-fill text-danger spaces ml-10  "
              ></i>
            ),
          },
        ]
      : []),

    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    {
      name: "Mã vật tư",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => row.item?.code,
    },
    {
      name: "Tên vật tư",
      field: "",
      headerStyle: {
        minWidth: 300,
      },
      cellStyle: {
        textAlign: "lefr",
      },
      render: (row: any, index: number, stt: number) => (
        <AutocompleteObjectV2
          options={listVatTu || []}
          isSearchDefauilt={true}
          name={"itemId"}
          onChange={(options: any) => handleChangeSelect(options, index)}
          value={row.item || ""}
          className="autocomplete-custom-renderform  radius spaces width-100 h-29"
        />
      ),
    },
    {
      name: "Số lượng",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "rigth",
      },
      render: (row: any, index: number, stt: number) => (
        <TextField
          name="totalQuantity"
          type="number"
          value={row.totalQuantity || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, index)
          }
          disabled={isView}
        />
      )
    },
    {
      name: "Tồn kho",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "rigth",
      },
      render: (row: any, index: number, stt: number) => row.tonKho
    },
    {
      name: "Đơn giá",
      field: "",
      headerStyle: {
        minWidth: 140,
        maxWidth: 150,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.item?.sellingPrice),
    },
    {
      name: "VAT (%)",
      field: "",
      headerStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.item?.perVat,
    },
    {
      name: "Thành tiền",
      field: "",
      headerStyle: {
        minWidth: 140,
        maxWidth: 150,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => {
        let price = row.item?.sellingPrice || 0;
        let quantity = parseFloat(row.totalQuantity) || 0;
        return formatMoney(price * quantity);
      },
    },
    {
      name: "Số ĐK",
      field: "",
      headerStyle: {
        minWidth: 120,
        maxWidth: 150,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.item?.registrationNumber || "",
    },
    {
      name: "Số lô",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.item?.consignment || "",
    },
    {
      name: "HSD",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => formatDateToDDMMYYYY(row.item?.expiryDate),
    },
  ];

  return (
    <div className="spaces mt-12 border-table custom-table">
      <div className="d-flex justify-content-between">
        <h4 className="text-title fw-bold fs-5 spaces mt-8">Thông tin vật tư</h4>
        <div className="spaces mt-10">
          <Button className="btn btn-fill" onClick={handleAddRow}>
            <i className="bi bi-plus-lg text-pri fs-3"></i> Thêm
          </Button>
        </div>
      </div>
      <OctTable
        className="spaces table-responsive-nk"
        columns={columns}
        data={dsVatTu}
        noToolbar
        clearToolbar
        noPagination
      />
    </div>
  );
};

export default TableVTTuTruc;
