import { FC, useContext, useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import GenerateFormComponent from "../../../../component/generate-form/GenerateFormHTMLNoValid";
import { addVatTuXuatKho, getDsThuocXuatKho, updateVatTuXuatKho } from "../../../services/servicesXuatKho";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../../utils/Constant";
import { PhanHeXuatKhoContext } from "../../../PhanHeXuatKho";
import { toast } from "react-toastify";
import { WMS_STATUS } from "../../../constants/constantsXuatKho";
import { WMS_TYPE } from "../../../constants/constantsXuatKho";
import DynamicComponent from "../../../../component/GenerateDynamicComponent";

type Props = {
  statusPhieu: any,
  inventoryId: string | undefined,
  id: string | undefined,
  warehouseExportType?: any,
  warehouseId: number,
};

type VatTuProps = {
  warehouseId?: any,
  id?: string | undefined,
  listJsonGenerate: string,
  onSubmit: (values: any) => void,
  vatTuSelected: any,
  statusPhieu: any;
  randomValue: any;
  setInitialValues?: any;
  warehouseExportType?: any;
}

type FormValues = {
  formThemMoiVatTuXuatKho: any;
}

const FormVatTu = (props: VatTuProps) => {
  const { id, listJsonGenerate, warehouseId, setInitialValues, warehouseExportType, statusPhieu, onSubmit, vatTuSelected, randomValue } = props;
  const { values, setFieldValue, touched, errors } = useFormikContext<FormValues>();


  const renderButton = () => {
    if (WMS_TYPE.KHACH_HANG == warehouseExportType?.code) {
      return <>
        {!id && (
          <Button
            className="btn btn-fill custom-position-btn spaces min-w-100"
            onClick={(e) => onSubmit(values)}
          >
            {(vatTuSelected.id || vatTuSelected?.index >= 0) ? 'Cập nhật thuốc/VT' : 'Thêm thuốc/VT'}
          </Button>
        )}
      </>
    } else if (WMS_TYPE.NCC == warehouseExportType?.code) {
      return <>
        {WMS_STATUS.warehouse_approved != statusPhieu?.status && (
          <Button
            className="btn btn-fill custom-position-btn spaces min-w-100"
            onClick={(e) => onSubmit(values)}
          >
            {(vatTuSelected.id || vatTuSelected?.index >= 0) ? 'Cập nhật thuốc/VT' : 'Thêm thuốc/VT'}
          </Button>
        )}
      </>
    }
  }

  useEffect(() => {
    if (values && values.formThemMoiVatTuXuatKho.dataItem) {
      const data = values.formThemMoiVatTuXuatKho.dataItem || {};
      setFieldValue('formThemMoiVatTuXuatKho', {
        item: data.code,
        itemId: data.id,
        itemName: data.name,
        code: data.code,
        iventory: data.iventory || '',
        sellingPrice: data.sellingPrice || '',
        importPrice: data.importPrice || '',
        packaging: data.packaging || '',
        unitId: data.unitofMeasureName,
        unitofMeasureName: data.unitofMeasureName,
        concentration: data.concentration,
        dosage: data.dosage,
        countryofOrigin: data.countryofOrigin,
        manufacturer: data.manufacturer,
        totalQuantity: values.formThemMoiVatTuXuatKho?.totalQuantity,
        vat: data.vat,
        registrationNumber: data.registrationNumber,
        consignment: data.consignment || '',
        expiryDate: data.expiryDate,
        usageIns: data.usageIns || '',
        dataItem: values.formThemMoiVatTuXuatKho.dataItem
      })
    }
  }, [values.formThemMoiVatTuXuatKho.itemId]);

  useEffect(() => {
    const data = values.formThemMoiVatTuXuatKho;
    const quantity = parseFloat(values.formThemMoiVatTuXuatKho?.totalQuantity) || 0;
    const price = data.sellingPrice || data.importPrice || 0;
    const vat = data.vat || 0;
    setFieldValue('formThemMoiVatTuXuatKho', {
      ...values.formThemMoiVatTuXuatKho,
      totalAmount: (quantity * price * (1 + vat / 100)).toFixed(2),
    })
  }, [values.formThemMoiVatTuXuatKho?.totalQuantity, values.formThemMoiVatTuXuatKho?.importPrice, values.formThemMoiVatTuXuatKho?.sellingPrice, values.formThemMoiVatTuXuatKho?.vat]);

  useEffect(() => {
    if (randomValue) {
      setFieldValue('formThemMoiVatTuXuatKho', { formThemMoiVatTuXuatKho: {} });
    };
  }, [randomValue]);
  const handler = (values: any) => {
    setInitialValues({
      formThemMoiVatTuXuatKho: { ...vatTuSelected, ...values },
    })
  }
  return (
    <Form>
      <GenerateFormComponent
        // isView={!id}
        warehouseId={warehouseId}
        values={values}
        listFieldAuto={listJsonGenerate}
        setFieldValue={setFieldValue}
        touched={touched}
        propsRenderButton={handler}
        errors={errors}
        DynamicClass={"pl-36"}
        modelID='formThemMoiVatTuXuatKho'
        customComponent={DynamicComponent}
      />
      {renderButton()}
    </Form>
  )
};

const FormThemVatTu: FC<Props> = (props) => {
  const { inventoryId, statusPhieu, warehouseExportType, id, warehouseId } = props;
  const { setIsLoading } = useContext(AppContext);
  const { thongTinPhieu, setThongTinPhieu, vatTuSelected, setVatTuSelected, randomValue } = useContext(PhanHeXuatKhoContext);
  const validationSchema = Yup.object({});
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [initialValues, setInitialValues] = useState({
    formThemMoiVatTuXuatKho: {},
  });

  useEffect(() => {
    setInitialValues({
      formThemMoiVatTuXuatKho: { ...vatTuSelected, ...vatTuSelected?.dataItem },
    })
  }, [vatTuSelected]);


  const onSubmit = (values: any) => {
    const data = values.formThemMoiVatTuXuatKho || {};

    if (!data.code) {
      toast.warning('Chưa chọn vật tư');
      return;
    };

    if (!data.totalQuantity || data.totalQuantity <= 0 || data.totalQuantity > data.iventory) {
      const message = !data.totalQuantity
        ? 'Chưa nhập số lượng'
        : data.totalQuantity <= 0
        ? 'Số lượng phải lớn hơn 0'
        : `Số lượng tồn kho không đủ (Tồn kho: ${data.iventory || 0})`
      toast.warning(message);
      return;
    };

    setInitialValues({
      formThemMoiVatTuXuatKho: {},
    });

    setVatTuSelected({});
    const dataSubmit = {
      inventoryId: inventoryId,
      itemId: data.itemId,
      item: data,
      totalQuantity: Number(data.totalQuantity),
      warehouseId: warehouseId,
      id: vatTuSelected.id,
    };
    if (vatTuSelected?.id || vatTuSelected?.index?.toString()) {

      let lstThuoc = [...thongTinPhieu?.dsThuoc];
      let dataPhieu = { ...thongTinPhieu }
      lstThuoc[vatTuSelected?.index] = dataSubmit
      setThongTinPhieu({ ...dataPhieu, dsThuoc: lstThuoc });
    } else {
      let dsThuoc = [...thongTinPhieu?.dsThuoc || [], { ...dataSubmit }]

      setThongTinPhieu({ ...thongTinPhieu, dsThuoc: dsThuoc });
    }

  };
  useEffect(() => {
    if (warehouseExportType.modelIdVT) getListform();
  }, [warehouseExportType.modelIdVT]);

  const getListform = async () => {
    let data = await generateForm(warehouseExportType?.modelIdVT || "formThemMoiVatTuXuatKho", setIsLoading);
    setListJsonGenerate(data);
  };

  return (
    <div className="bg-white spaces px-10 pb-6 form-them-vat-tu-xk">
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <FormVatTu
          id={id}
          warehouseId={warehouseId}
          warehouseExportType={warehouseExportType}
          listJsonGenerate={listJsonGenerate}
          onSubmit={onSubmit}
          setInitialValues={setInitialValues}
          statusPhieu={statusPhieu}
          vatTuSelected={vatTuSelected}
          randomValue={randomValue}
        />
      </Formik>
    </div>
  );
};

export default FormThemVatTu;
