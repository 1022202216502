import { AxiosResponse } from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../appContext/AppContext";
import { RESPONSE_MESSAGE } from "../../../utils/Constant";
import { EXPORT_TYPE } from "../constant";
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import FilePreview from "./FilePreview";
import PrintAction from "./PrintAction";

type FormInPhieuProps<T> = {
    show: boolean;
    title?: string;
    onHide: () => void;
    fetchExport: (params: T) => Promise<AxiosResponse<Blob, any>>;
    params: T;
};

const FormInPhieuDialog = <T extends object>({ show, onHide, title = 'In Phiếu', fetchExport, params }: FormInPhieuProps<T>) => {
    const { setIsLoading, isLoading } = useContext(AppContext);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const [srcPreview, setSrcPreview] = useState<string>();

    useEffect(() => {
        onExport(EXPORT_TYPE.PDF, false);
    }, []);

    const onPrint = () => {
        if (iframeRef.current) {
            iframeRef.current.focus();
            iframeRef.current.contentWindow?.print();
        }
    };

    const onExport = async (exportType: string = EXPORT_TYPE.PDF, isExportButton = true) => {
        try {
            setIsLoading(true);

            if (!params) return;

            const { data, headers } = await fetchExport({ ...params, exportType: exportType });
            const file = new Blob([data], { type: "application/pdf" });
            if (!isExportButton) {
                const URLPreview = URL.createObjectURL(file);
                setSrcPreview(URLPreview);
                return;
            }
            const fileName = getFileName(headers['content-disposition']);
            saveAs(file, fileName);
        } catch (error) {
            console.error(error);
            toast.error(RESPONSE_MESSAGE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const getFileName = (header: string = ''): string => {
        const fileName = header.split(';').find(key => key.includes('fileName'));

        if (!fileName) return '';

        return fileName.split('=')[1].trim();
    };

    return (
        <Modal centered show={show} onHide={onHide} fullscreen>
            <Modal.Header closeButton className="py-5 bg-white">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="p-8">
                <div className="d-flex flex-row h-100">
                    <div className="flex-grow-1">
                        {!isLoading && !srcPreview ? (
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <em className="fs-3 text-gray-600">Không có dữ liệu.</em>
                            </div>
                        ) : (
                            <FilePreview src={srcPreview} ref={iframeRef} />
                        )}
                    </div>
                    <div className="w-150 ms-3 ps-5 border-start">
                        <PrintAction onPrint={onPrint} onExport={onExport} />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn btn-danger" onClick={onHide}>
                    Đóng
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FormInPhieuDialog;