import { IconMenu } from "../../component/IconSvg"

export const TreeQuanLyThau = {
  code: "ALL",
  name: "Tất cả thầu",
  icon: <IconMenu />,
  filter: [
    {
      code: "LOAI_PHIEU",
      name: "Loại phiếu",
      filter: [
        {
          code: "THUOC",
          name: "Thuốc",
        },
        {
          code: "VAT_TU",
          name: "Vật tư",
        },
      ],
    },
    {
      code: "LOAI_THAU",
      name: "Loại thầu",
      filter: [
        {
          code: "KE_HOACH",
          name: "Kế hoạch",
        },
        {
          code: "DOT_XUAT",
          name: "Đột xuất",
        },
        {
          code: "MUA_LE",
          name: "Mua lẻ",
        },
      ],
    },
  ],
};