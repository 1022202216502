import { Column } from "react-table";
import { TableCustomHeader } from "../../table/components/TableCustomHeader";
import { TableCustomCell } from "../../table/components/TableCustomCell";
import { formatDateToDDMMYYYY, formatMoney } from "../../../utils/FormatUtils";

export { };

export const columnsDSVatTu: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mã vật tư"
                className="text-white text-center align-middle min-w-100px"
            />
        ),
        id: "code",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.code}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tên vật tư"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "name",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.name}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Đơn vị"
                className=" text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "unitofMeasureName",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.unitofMeasureName}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Số lô"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "consignment",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.consignment}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tồn kho"
                className=" text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "iventory",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={formatMoney(props.data[props.row.index]?.iventory)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Chờ xuất"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "peShipment",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={formatMoney(props.data[props.row.index]?.peShipment) || 0}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Đóng băng"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "dongBang",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={formatMoney(props.data[props.row.index]?.dongBang) || ''}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="HSD"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "expiryDate",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center s-2"
                data={formatDateToDDMMYYYY(props.data[props.row.index]?.expiryDate)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Số đăng ký"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "registrationNumber",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.registrationNumber}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Giá mua"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "servicePrice",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={formatMoney(props.data[props.row.index]?.servicePrice) || ''}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Giá bán"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "sellingPrice",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={formatMoney(props.data[props.row.index]?.sellingPrice) || ''}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="QĐ thầu"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "serialNumber",
        Cell: ({ ...props }) => {
            const row = props.data[props.row.index] || {};
            return (
                <TableCustomCell
                    className="s-2"
                    data={props.data[props.row.index]?.serialNumber}
                />
            )
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Số gói thầu"
                className="text-center text-white align-middle bg-pri min-w-130px"
            />
        ),
        id: "biddingPackage",
        Cell: ({ ...props }) => {
            const row = props.data[props.row.index] || {};
            return (
                <TableCustomCell
                    className="s-2"
                    data={props.data[props.row.index]?.biddingPackage}
                />
            )
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Nhóm thầu"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "biddingGroup",
        Cell: ({ ...props }) => {
            const row = props.data[props.row.index] || {};
            return (
                <TableCustomCell
                    className="s-2"
                    data={props.data[props.row.index]?.biddingGroup}
                />
            )
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Năm thầu"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "biddingYear",
        Cell: ({ ...props }) => {
            const row = props.data[props.row.index] || {};
            return (
                <TableCustomCell
                    className="text-center s-2"
                    data={props.data[props.row.index]?.biddingYear}
                />
            )
        }
    },
];