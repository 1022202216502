import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, InputGroup, Modal, Row, Stack } from "react-bootstrap";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { Formik, FormikHelpers } from "formik";
import { VatTu, initialValuesThemMoiVatTu } from "./models/ThemMoiVatTuModel";
import { useIntl } from "react-intl";
import FormThemMoi from "./components/FormThemMoi";
import "./ThemMoiVatTu.scss";
import { addVatTu, updateVatTu } from "../../services/ThongKeServices";
import { CODE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../PhanHeThongKe"

type Props = {
  show: boolean;
  onHide: () => void;
};


const DialogThemMoiVatTu: FC<Props> = (props) => {
  const { show, onHide } = props;
  const intl = useIntl();
  const { setIsLoading } = useContext(AppContext);
  const { dataVatTuEdit, nhomVatTu } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);
  const [thongTinVatTu, setThongTinVatTu] = useState<VatTu>(initialValuesThemMoiVatTu);
  let validationSchema = Yup.object({
    // thongTinChung: Yup.object({
    //   code: Yup.string()
    //     .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable()
    // }),
    // thongTinCoBanThuocAdd:Yup.object({
    //   actIngCode: Yup.string()
    //     .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable()
    // })
  })
  const constModel = [
    "thongTinChung",
    "thongTinChungVatTu",
    "thongTinChungMau",
    "thongTinChungHangHoa",
  ]
  const ConvertModel = () => {
    if (nhomVatTu?.id) return constModel[nhomVatTu?.id - 1];
    return "thongTinChung"
  }
  const handlerConvertValues = (data: any) => {
    let dataThuoc = data;
    let dataVatTu = data;
    let dataMau = data;
    dataThuoc = {
      id: data?.id || dataVatTuEdit?.id,
      thongTinChung: {
        ...data?.thongTinChung,
        materialGroup:  nhomVatTu?.id
      },
      thongTinCoBanThuocAdd: data?.thongTinCoBanThuocAdd,
      thongTinKeToanThuocAdd: data?.thongTinKeToanThuocAdd,
      thongTinXuatNhapKhoThuocAdd: data?.thongTinXuatNhapKhoThuocAdd,
      thongTinKhacThuocAdd: data?.thongTinKhacThuocAdd,
      thongTinLamSangThuocAdd: data?.thongTinLamSangThuocAdd
    }
    dataVatTu = {
      id: data?.id || dataVatTuEdit?.id,
      thongTinChungVatTu: {
        ...data?.thongTinChungVatTu,
        materialGroup:  nhomVatTu?.id
      },
      thongTinCoBanVatTuAdd: data?.thongTinCoBanVatTuAdd,
      thongTinKeToanVatTuAdd: data?.thongTinKeToanVatTuAdd,
      thongTinXuatNhapKhoVatTuAdd: data?.thongTinXuatNhapKhoVatTuAdd,
      thongTinKhacVatTuAdd: data?.thongTinKhacVatTuAdd
    }
    dataMau = {
      id: data?.id || dataVatTuEdit?.id,
      thongTinChungMau: {
        ...data?.thongTinChungMau,
        materialGroup:  nhomVatTu?.id
      },
      thongTinCoBanMauAdd: data?.thongTinCoBanMauAdd,
      thongTinKeToanMauAdd: data?.thongTinKeToanMauAdd,
      thongTinKhacMauAdd: data?.thongTinKhacMauAdd,
    }
    if (nhomVatTu?.id == 1) return dataThuoc
    if (nhomVatTu?.id == 2) return dataVatTu
    if (nhomVatTu?.id == 3) return dataMau
    return {}
  }
  const handleFormSubmit = async (values: any) => {
    let dataValues = handlerConvertValues(values);
    const modelID = ConvertModel();
    try {
      setIsLoading(true);
      let { data } = (dataValues?.id || dataVatTuEdit?.id)
        ? await updateVatTu({
          lstModelType: dataValues,
        })
        : await addVatTu({
          lstModelType: dataValues
        }, modelID);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success(((dataValues?.id) ? "Cập nhật" : "Thêm") + " vật tư thành công");
        onHide()
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
  }
  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={onHide}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Modal.Header className="header-modal min-h-40px ">
          <div className="d-flex gap-6">
            <div
              className="d-flex align-items-center title-back gap-3 cursor-pointer"
              onClick={onHide}
            >
              <i className="spaces bi bi-chevron-left ml-10"> </i>
              <div className="spaces mr-20  my-3">Quay lại</div>
            </div>
            <div className="spaces mt-4 title">Thêm mới vật tư</div>
          </div>
        </Modal.Header>
        <Modal.Body className="spaces p-0 mt-5 bg-white">
          <Formik<VatTu>
            initialValues={{ ...thongTinVatTu }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            <FormThemMoi isReload={show} onHide={onHide} />
          </Formik>
        </Modal.Body>

      </Modal>
    </div>
  );
};
export default DialogThemMoiVatTu;
