
import { OctTable } from "@oceantech/oceantech-ui";
import { Dispatch, SetStateAction, useContext } from "react";
import { formatMoney } from "../../utils/FormatUtils";
import { PhanHeNhapKhoContext } from "../PhanHeNhapKho";
import '../PhanHeNhapKho.scss';
import { WMS_STATUS } from "../constants/constansNhapKho";
interface Props {
  isView?: boolean;
  setIndexRowEdit: Dispatch<SetStateAction<number | null>>,
  setInitialValuesFormThemVT: Dispatch<SetStateAction<any>>,
}

const TableThongTinNhapThuoc = (props: Props) => {
  const { isView, setIndexRowEdit, setInitialValuesFormThemVT } = props;
  const { dsNhapKho, setDsNhapKho, detailPhieuNhap } = useContext(PhanHeNhapKhoContext);

  const hanldeDeleteRow = (index: number) => {
    const data = [...dsNhapKho].filter((_: any, i: number) => i !== index);
    setDsNhapKho(data);
  };
  const handleEdit = (rowData: any, index: number) => {
    setInitialValuesFormThemVT({ ...rowData,...rowData?.item,id:rowData?.id || null, code: rowData.code || rowData.item?.code });
    setIndexRowEdit(index);
  }

  const columns = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    ...(!isView ?
      [
        {
          name: "TT",
          field: "",
          headerStyle: {
            minWidth: 80,
            maxWidth: 80,
          },
          cellStyle: {
            textAlign: "center",
          },
          render: (row: any, index: number, stt: number) =>
            <div className="d-flex justify-content-center">
              <>
                <i
                  onClick={(e) => handleEdit(row, index)}
                  className="bi bi-pencil-square text-navy"
                ></i>
                <i
                  onClick={(e) => hanldeDeleteRow(index)}
                  className="bi bi-trash3-fill text-danger spaces ml-10  "
                ></i>
              </>
            </div>
        },
      ] : []),

    {
      name: "Mã vật tư",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.code || row.item?.code || '',
    },
    {
      name: "Tên vật tư",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name || '',

    },
    {
      name: "ĐVT",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.unitofMeasureName || row.item?.unitofMeasureName || '',
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalQuantity) || '',
    },
    {
      name: "Giá mua",
      field: "sellingPrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.sellingPrice) || '',
    },
    {
      name: "VAT",
      field: "vat",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.vat || '',
    },
    {
      name: "Giá mua thực",
      field: "",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => {
        let price = parseFloat(row.sellingPrice || 0);
        let vat = parseFloat(row.vat || 0);
        return formatMoney(price * (1 + vat / 100));
      },
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalAmount) || '',

    },
  ];

  return (
    <div className="spaces mt-12 border-table custom-table">
      <h4 className="spaces mt-8 ml-10 title">Thông tin nhập thuốc</h4>
      <OctTable
        id="table-tt-nhap-thuoc"
        className="spaces custom-header-table"
        columns={columns}
        data={dsNhapKho}
        noToolbar
        clearToolbar
        noPagination
      />
    </div>
  );
};

export default TableThongTinNhapThuoc;
