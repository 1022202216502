import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Column, Row, useTable } from 'react-table';
import './tableThuoc.scss'

interface Props<T extends object> {
	data: T[];
	columns: Readonly<Column<T>[]>;
	containerClassName?: string;
	tableClassName?: string;
	onSelect?: (selectedRows: any) => void;
	onRowDoubleClick?: (row: Row<T>) => void;
	selectedRowIndex?: number;
}

function TableSelect<T extends object>({
	data,
	containerClassName,
	tableClassName,
	onSelect,
	onRowDoubleClick,
	columns: tableColumns,
	selectedRowIndex,
}: Props<T>) {
	const [tableData, setTableData] = useState<T[]>([]);
	const { formatMessage } = useIntl();

	const tableBodyRef = useRef<HTMLTableSectionElement>(null);

	const { rows, getTableProps, getTableBodyProps, headerGroups, prepareRow, columns } =
		useTable<T>({
			columns: tableColumns,
			data: tableData,
		});

	useEffect(() => {
		setTableData(data);
	}, [data]);

	const EmptyRow = () => {
		return (
			<tr>
				<td colSpan={columns.length} className="text-center py-2">
					{formatMessage({ id: 'TABLE.DATA.EMPTY' })}
				</td>
			</tr>
		);
	};

	const TableRow = ({ row }: { row: Row<T> }) => {
		return (
			<tr
				{...row.getRowProps()}
				className={clsx('cursor-pointer', {
					'selected-row': row.index === selectedRowIndex,
				})}
				onClick={() => {
					onSelect && onSelect(row.original);
				}}
				onDoubleClick={() => {
					onRowDoubleClick && onRowDoubleClick(row);
				}}
			>
				{row.cells.map((cell: any) => {
					return (
						<td
							className={clsx('align-middle spaces py-4 px-8')}
							{...cell.getCellProps()}
						>
							{cell.render('Cell')}
						</td>
					);
				})}
			</tr>
		);
	};

	return (
		<div className={clsx('table-responsive w-100', containerClassName)}>
			<table
				id="kt_table_custom_select"
				className={clsx('align-middle table-bordered w-100', tableClassName)}
				{...getTableProps()}
			>
				<thead className="position-sticky top-0 z-index-1">
					{headerGroups.map(headerGroup => (
						<tr
							className="text-start border fw-bolder fs-8 gs-0 border bg-pri border-y-none "
							{...headerGroup.getHeaderGroupProps()}
						>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()} className="p-2 text-center">
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody
					className="text-gray bg-white border"
					{...getTableBodyProps()}
					ref={tableBodyRef}
				>
					{!tableData.length ? (
						<EmptyRow />
					) : (
						<>
							{rows.map((row, i) => {
								prepareRow(row);
								return <TableRow key={row.id} row={row} />;
							})}
						</>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default TableSelect;
