import { Form, Formik } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import GenerateFormComponent from "../../../component/generate-form/GenerateFormHTMLNoValid";
import DynamicComponent from "../../../component/GenerateDynamicComponent";
import { AppContext } from "../../../appContext/AppContext";
import { generateForm } from "../../../utils/AppFunction";

type Props = {
  warehouseExportType?: any
  warehouseId: number
  dsVatTu: any
  setDsVatTu: Dispatch<SetStateAction<any>>
  vatTuSelected: any
  setVatTuSelected :Dispatch<SetStateAction<any>>
};

type VatTuProps = {
  warehouseId?:any;
  listJsonGenerate: string;
  setInitialValues?: any;
  touched: any;
  errors: any;
  values: any;
  setFieldValue: any;
  vatTuSelected: any;
}

type FormValues = {
  formThemMoiVatTuXuatKho: any;
}

const FormVatTu = (props: VatTuProps) => {
  const {listJsonGenerate,warehouseId, setInitialValues, touched,  errors, values, setFieldValue, vatTuSelected } = props;

  useEffect(() => {
    if (values && values.formThemMoiVatTuXuatKho?.dataItem) {
      const data = values.formThemMoiVatTuXuatKho?.dataItem || {};
      setFieldValue('formThemMoiVatTuXuatKho', {
        item: data.code,
        itemId: data.id,
        itemName: data.name,
        code: data.code,
        iventory: data.iventory || '',
        sellingPrice: data.sellingPrice || '',
        importPrice: data.importPrice || '',
        packaging: data.packaging || '',
        unitId: data.unitofMeasureName,
        unitofMeasureName: data.unitofMeasureName,
        concentration: data.concentration,
        dosage: data.dosage,
        countryofOrigin: data.countryofOrigin,
        manufacturer: data.manufacturer,
        totalQuantity: values.formThemMoiVatTuXuatKho?.totalQuantity,
        vat: data.vat,
        registrationNumber: data.registrationNumber,
        consignment: data.consignment || '',
        expiryDate: data.expiryDate,
        usageIns: data.usageIns || '',
        dataItem: data,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.formThemMoiVatTuXuatKho?.itemId]);
  useEffect(() => {
    const data = values.formThemMoiVatTuXuatKho;
    const quantity = parseFloat(values.formThemMoiVatTuXuatKho?.totalQuantity) || 0;
    const price = data.sellingPrice || data.importPrice || 0;
    const vat = data.vat || 0;
    setFieldValue("formThemMoiVatTuXuatKho", {
      ...values.formThemMoiVatTuXuatKho,
      totalAmount: (quantity * price * (1 + vat / 100)).toFixed(2),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.formThemMoiVatTuXuatKho?.totalQuantity,
    values.formThemMoiVatTuXuatKho?.importPrice,
    values.formThemMoiVatTuXuatKho?.sellingPrice,
    values.formThemMoiVatTuXuatKho?.vat,
  ]);

  const handler = (values: any) => {
    setInitialValues({
      formThemMoiVatTuXuatKho: { ...vatTuSelected, ...values },
    })
  }
  return (
      <GenerateFormComponent
        values={values}
        warehouseId={warehouseId}
        listFieldAuto={listJsonGenerate}
        setFieldValue={setFieldValue}
        touched={touched}
        propsRenderButton={handler}
        errors={errors}
        modelID='formThemMoiVatTuXuatKho'
        customComponent={DynamicComponent}
        DynamicClass={'pl-36'}
      />
  )
};

const FormThemVatTu: FC<Props> = (props) => {
  const { warehouseExportType, warehouseId, dsVatTu, setDsVatTu, vatTuSelected, setVatTuSelected } = props;
  const { setIsLoading } = useContext(AppContext);
  const validationSchema = Yup.object({
    formThemMoiVatTuXuatKho: Yup.object({})
  });
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [initialValues, setInitialValues] = useState({
    formThemMoiVatTuXuatKho: vatTuSelected,
  });

  const onSubmit = (values: any) => {
    const dataItem = values.formThemMoiVatTuXuatKho || {};    
    if (!dataItem.code) {
      toast.warning('Chưa chọn vật tư');
      return;
    };

    if (!dataItem.totalQuantity || dataItem.totalQuantity <= 0 || dataItem.totalQuantity > dataItem.iventory) {
      const message = !dataItem.totalQuantity
        ? 'Chưa nhập số lượng'
        : dataItem.totalQuantity <= 0
        ? 'Số lượng phải lớn hơn 0'
        : `Số lượng tồn kho không đủ (Tồn kho: ${dataItem.iventory || 0})`
      toast.warning(message);
      return;
    };

    setInitialValues({
      formThemMoiVatTuXuatKho: {},
    });
    setVatTuSelected({});
    const dataSubmit = {
      itemId: dataItem.id,
      item: dataItem,
      totalQuantity: Number(dataItem.totalQuantity),
      warehouseId: warehouseId,
    };
    if (vatTuSelected?.id || vatTuSelected?.index?.toString()) {
      let newDsVatTu = [...dsVatTu];
      newDsVatTu[vatTuSelected?.index] = dataSubmit;
      setDsVatTu(newDsVatTu);
    } else {
      let newDsVatTu = [...(dsVatTu || []), { ...dataSubmit }]      
      setDsVatTu(newDsVatTu);
    }
  };

  useEffect(() => {
    setInitialValues({formThemMoiVatTuXuatKho : vatTuSelected});
  }, [vatTuSelected]);

  useEffect(() => {
    if (warehouseExportType.modelIdVT) getListform();
  }, [warehouseExportType.modelIdVT]);

  const getListform = async () => {
    let data = await generateForm(warehouseExportType?.modelIdVT || "formThemMoiVatTuXuatKho", setIsLoading);
    setListJsonGenerate(data);
  };

  return (
    <div className="bg-white spaces px-10 pb-6 form-them-vat-tu-xk">
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form>
            <FormVatTu
              listJsonGenerate={listJsonGenerate}
              setInitialValues={setInitialValues}
              vatTuSelected={vatTuSelected}
              values={values}
              warehouseId={warehouseId}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            />
            <Button onClick={e => onSubmit(values)} className="btn btn-fill spaces mt-6 min-w-100">
              {!vatTuSelected.index?.toString() ? "Thêm thuốc/VT" : "Cập nhật thuốc/VT"}
            </Button>
          </Form> 
        )}
      </Formik>
    </div>
  );
};

export default FormThemVatTu;
