import React, { FC, useContext } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./confirmDialog.scss";
import {
  // OctAutocomplete,
  // OctTextValidator,
} from "@oceantech/oceantech-ui";
import OCTAutocomplete from "../autocompleteOct"
import OCTTextValidator from "../text-validator"
import LabelRequired from "../../component/LabelRequired";
import TextField from "../../component/TextField";

import {
  COMPONENT_TYPE,
  TYPE,
  IObject,
  paramsConfig,
  replaceUrl,
  isSuccessfulResponse,
  handleThrowResponseMessage,
  API_KEY_EDITOR,
} from "./GenerateFormConfig";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import * as Yup from "yup";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import './generateForm.scss'
interface IDialogProps {
  listFieldAuto?: any;
  onCloseClick?: () => void;
  handleSave?: (data: any) => void;
  onCancelClick?: () => void;
  title?: string;
  message?: string;
  isView?: boolean;
  isUpdate?: boolean;
  itemEdit?: IObject;
  validation?: IObject;
}
interface ItemTypeProps {
  autofocus?: boolean;
  clearOnHide?: boolean;
  conditional?: IObject;
  defaultValue?: string;
  hidden?: boolean;
  input?: boolean;
  inputType?: string;
  inputFormat?: string;
  inputMask?: string;
  key: string;
  label?: string;
  lablelPosition?: string;
  multiple?: boolean;
  placeholder?: string;
  persistent?: boolean;
  prefix?: string;
  protected?: boolean;
  properties?: IObject;
  spellcheck?: boolean;
  suffix?: string;
  unique?: boolean;
  width?: number;
  offset?: number;
  push?: number;
  pull?: number;
  type?: string;
  value?: string;
  tag?: keyof JSX.IntrinsicElements;
  tags?: string[];
  validate?: IObject;
  columns?: IObject;
  values?: IObject;
  components?: ItemTypeProps[];
  data?: IObject;
  filePattern?: string;
  fileMaxSize?: string;
  fileMinSize?: string;
  format?: string;
  url?: string;
  fields?: any;
  content?: string;
  hideLabel?: boolean;
  inline?: boolean;
  className?: string;
}
const GenerateFormComponent: FC<IDialogProps> = (props) => {
  const { onCloseClick, handleSave, listFieldAuto, itemEdit = null, isUpdate = false } = props;
  const customValidation = { ...props.validation };
  const { lang } = useMultiLanguage();
  const { setIsLoading } = useContext(AppContext);
  const validation = () => {
    let shape: any = {};
    const shapeType: IObject = {
      [TYPE.TEXT]: "string",
      [TYPE.MULTILINE]: "string",
      [TYPE.TEXTAREA]: "string",
      [TYPE.NUMBER]: "number",
      [TYPE.DATE]: TYPE.DATE,
      [COMPONENT_TYPE.EMAIL]: "string",
      [COMPONENT_TYPE.DAY]: "number",
    };

    listFieldAuto?.components?.forEach((item: ItemTypeProps) => {
      // fakeData?.components?.forEach((item: any) => {
      let schemaType = shapeType[item?.inputType || ""];
      shape[item?.key] = createYupSchema(schemaType, item.validate);
    });

    return Yup.object().shape({
      ...shape,
      ...customValidation,
    });
  };

  const getInitialValues = (listField: any) => {
    const newItem: IObject = {};

    listField?.components?.forEach((item: ItemTypeProps) => {
      if (item.type === COMPONENT_TYPE.SELECTBOXES) {
        newItem[item.key] = item.values || [];
      } else {
        newItem[item.key] = "";
      }
    });

    return newItem;
  };

  const getYupSchemaType = (type: any) => {
    switch (type) {
      case "string":
        return Yup.string();
      case "number":
        return Yup.number();
      case "date":
        return Yup.date();
      case "mixed":
        return Yup.mixed();
      default:
        return Yup.mixed(); // Sử dụng mixed làm kiểu mặc định nếu không xác định được
    }
  };

  const createYupSchema = (type: string, validate?: IObject) => {
    let newSchema: any = getYupSchemaType(type || "");

    if (!validate) return newSchema;
    if (validate.required) {
      newSchema = newSchema.required(lang("VALIDATION.REQUIRE"));
    }
    if (validate.minLength) {
      newSchema = newSchema.min(
        Number(validate.minLength),
        lang("VALIDATION.MIN_LENGTH_STRING") + validate.minLength
      );
    }
    if (validate.maxLength) {
      newSchema = newSchema.max(
        Number(validate.maxLength),
        lang("VALIDATION.MAX_LENGTH_STRING") + validate.maxLength
      );
    }

    return newSchema;
  };

  const formik = useFormik({
    initialValues: {
      ...getInitialValues(listFieldAuto),
      ...itemEdit,
    },
    onSubmit: async () => {
      let dataSend = {
        ...itemEdit,
        ...formik.values,
      };
      handleSave && handleSave({ dataSend, isUpdate });
      return;
    },
    validationSchema: validation,
  });

  const handleChangeFile = async (e: any, item: ItemTypeProps) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("uploadedFile", file);

    if (item.url) {
      try {
        setIsLoading(true);
        let res = await uploadFile(item?.url, formData);
        const data = res.data;
        if (isSuccessfulResponse(res.status)) {
          setIsLoading(false);
          let attachment = data.file || data?.data;
          let attachments = [];
          let fileDescriptionIds = [];

          attachments.push(attachment);
          fileDescriptionIds.push(attachment?.id);
          await formik.setFieldValue(e.target.name, fileDescriptionIds);
          toast.success(lang("GENERAL.SUCCESS"));
        } else {
          handleThrowResponseMessage(res);
        }
      } catch {
        toast.error(lang("GENERAL.ERROR"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const uploadFile = (url: string | undefined | null, formData: any) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.post(url || "", formData, config);
  };

  const handleChangeCheck = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.checked);
  };

  const handleChangeDate = (e: any, format?: string, item?: ItemTypeProps) => {
    // const newFormat = format || DATE_FORMAT.ISO_DATE;
    // let value = moment(e.target.value).isValid() ? moment(e.target.value).format(newFormat) : "";

    formik.setFieldValue(e.target.name, e.target.value);
  };

  const handleGetDataByUrlData = (
    urlData: string,
    searchObject?: any
  ): Promise<AxiosResponse<any>> => {
    return axios.get(urlData, paramsConfig(searchObject));
  };

  const handleChangeSelectBoxes = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    source: string
  ) => {
    let newArray = formik.values?.[source] || [];
    newArray[index].value = e.target.checked;

    formik.setFieldValue(source, newArray);
  };

  const handleChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    let day = formik.values?.day || {};
    day[e.target.name] = e.target.value;

    formik.setFieldValue("day", day);
  };


  const renderHTML = (dataForm: any, configKey?: string) => {
    if (dataForm?.components || dataForm?.[configKey || "components"]) {
      let newArray = dataForm?.components || dataForm?.[configKey || ""] || [];

      if (Object.prototype.toString.call(newArray) === "[object Object]") {
        newArray = Object.entries(newArray).map(([key, value]) => {
          return value;
        });
      }

      let filteredComponents = newArray?.filter(
        (item: ItemTypeProps) => item.type !== COMPONENT_TYPE.BUTTON
      );
      return filteredComponents?.map((dataComponent: ItemTypeProps) => {
        switch (dataComponent?.type) {
          case COMPONENT_TYPE.EMAIL:
          case COMPONENT_TYPE.PASSWORD:
          case COMPONENT_TYPE.TEXTFIELD:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className="spaces mb-3 pt-8"
                >
                  <OCTTextValidator
                    hideLabel={dataComponent?.hideLabel}
                    lable={dataComponent?.label}
                    name={dataComponent?.key}
                    type={dataComponent.inputType}
                    onChange={formik.handleChange}
                    isRequired={dataComponent?.validate?.required}
                    value={formik?.values?.[dataComponent?.key]}
                    touched={formik.touched?.[dataComponent?.key]}
                    errors={formik.errors?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.COLUMNS:
            return (
              <Row>
                {dataComponent?.columns &&
                  dataComponent?.columns?.length > 0 &&
                  dataComponent?.columns?.map((dataColumn: ItemTypeProps) => {
                    return (
                      <Col lg={dataColumn?.width}>{renderHTML(dataColumn)}</Col>
                    );
                  })}
              </Row>
            );
          case COMPONENT_TYPE.FILE:
            return (
              <>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="text-lable-input lable m-0">
                    {dataComponent?.label}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name={dataComponent.key}
                    max={dataComponent?.fileMaxSize}
                    min={dataComponent?.fileMinSize}
                    accept={dataComponent?.filePattern}
                    onChange={(e) => handleChangeFile(e, dataComponent)}
                  // value={formik?.values?.[dataComponent?.key]}
                  />
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.DAY:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Row>
                  <Col
                    lg={3}
                    className="spaces pr-0 mb-3 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      lable={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={formik.handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={formik?.values?.[dataComponent?.key]}
                      touched={formik.touched?.[dataComponent?.key]}
                      errors={formik.errors?.[dataComponent?.key]}
                      placeholder={""}
                    />
                  </Col>
                  <Col
                    lg={3}
                    className="spaces mb-3 pl-0 pr-0 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      lable={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={formik.handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={formik?.values?.[dataComponent?.key]}
                      touched={formik.touched?.[dataComponent?.key]}
                      errors={formik.errors?.[dataComponent?.key]}
                      placeholder={""}
                    />
                  </Col>
                  <Col
                    lg={3}
                    className="spaces mb-3 pl-0 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      lable={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={formik.handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={formik?.values?.[dataComponent?.key]}
                      touched={formik.touched?.[dataComponent?.key]}
                      errors={formik.errors?.[dataComponent?.key]}
                      placeholder={""}
                    />
                  </Col>
                  {dataComponent?.key == "ngaySinh" &&
                    <>
                      <Col
                        lg={1}
                        className="spaces mb-3 pl-0 pt-8"
                      >
                        <div className="spaces d-flex flex-center fw-bold">
                          -
                        </div>
                      </Col>

                      <Col
                        lg={2}
                        className="spaces mb-3 pl-0 pt-8"
                      >
                        <OCTTextValidator
                          hideLabel={dataComponent?.hideLabel}
                          lable={""}
                          name={dataComponent?.key}
                          type={"text"}
                          onChange={formik.handleChange}
                          isRequired={dataComponent?.validate?.required}
                          value={formik?.values?.[dataComponent?.key]}
                          touched={formik.touched?.[dataComponent?.key]}
                          errors={formik.errors?.[dataComponent?.key]}
                          placeholder={""}
                        />
                      </Col>
                    </>
                  }
                </Row>
              </>
            );
          case COMPONENT_TYPE.TIME:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Control
                  type="time"
                  placeholder="Time"
                  onChange={formik.handleChange}
                  value={formik?.values?.[dataComponent?.key]}
                />

              </>
            );
          case COMPONENT_TYPE.DATETIME:
            return (
              <>
                <Col
                  lg={12}
                  className="spaces mb-3 pt-8"
                >
                  <OCTTextValidator
                    hideLabel={dataComponent?.hideLabel}
                    lable={dataComponent?.label}
                    name={dataComponent?.key}
                    type={"date"}
                    onChange={formik.handleChange}
                    isRequired={dataComponent?.validate?.required}
                    value={formik?.values?.[dataComponent?.key]}
                    touched={formik.touched?.[dataComponent?.key]}
                    errors={formik.errors?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                  />
                </Col>

              </>
            );
          case COMPONENT_TYPE.NUMBER:
          case COMPONENT_TYPE.PHONE:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Control
                  type="number"
                  name={dataComponent?.key}
                  placeholder={dataComponent?.placeholder}
                  value={formik?.values?.[dataComponent?.key]}
                  onChange={formik.handleChange}
                />
              </>
            );
          case COMPONENT_TYPE.TEXTAREA:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Control
                  as="textarea"
                  name={dataComponent?.key}
                  placeholder={dataComponent?.placeholder}
                  onChange={formik.handleChange}
                  value={formik?.values?.[dataComponent?.key]}
                />
              </>
            );
          case COMPONENT_TYPE.CHECKBOX:
            return (
              <>
                {/* { dataComponent?.hideLabel === false && {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}} */}
                <Form.Group className="spaces pt-10" controlId={dataComponent?.key}>
                  <Form.Check
                    type="checkbox"
                    label={dataComponent?.label}
                    onChange={handleChangeCheck}
                    name={dataComponent.key}
                    checked={formik?.values?.[dataComponent?.key]}
                  />
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.SELECTBOXES:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Group controlId={dataComponent?.key} className={`${dataComponent?.inline && 'd-flex gap-10'}`}>
                  {dataComponent?.values?.map(
                    (dataItem: any, index: number) => (
                      <Form.Check
                        type="checkbox"
                        label={dataItem?.label}
                        checked={Boolean(
                          formik?.values[dataComponent?.key]?.[index]?.value
                        )}
                        onChange={(e) =>
                          handleChangeSelectBoxes(e, index, dataComponent?.key)
                        }
                      />
                    )
                  )}
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.RADIO:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Group className="d-flex spaces pt-8" controlId="formBasicCheckbox">
                  {dataComponent?.values?.map((dataItem: any) => (
                    <Form.Check
                      inline
                      type="radio"
                      label={dataItem?.label}
                      value={dataItem.value}
                      name={dataComponent?.key}
                      checked={
                        formik?.values?.[dataComponent?.key] === dataItem.value
                      }
                      onChange={formik.handleChange}
                    />
                  ))}
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.SELECT:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className="spaces"
                >
                  <OCTAutocomplete
                    lable={dataComponent?.label}
                    hideLabel={dataComponent?.hideLabel}
                    options={[]}
                    name={dataComponent?.key}
                    onChange={(e: any) => {
                      formik.setFieldValue(dataComponent?.key, e?.id);
                    }}
                    valueSearch="name"
                    searchObject={{}}
                    searchFunction={() =>
                      handleGetDataByUrlData(
                        replaceUrl(dataComponent?.data?.url),
                        {}
                      )
                    }
                    value={formik?.values?.[dataComponent?.key]}
                    touched={formik.touched?.[dataComponent?.key]}
                    errors={formik.errors?.[dataComponent?.key]}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.BUTTON:
            return (
              <>
                <Button>
                  {lang(`BTN.${dataComponent?.label?.toUpperCase()}`)}
                </Button>
              </>
            );
          case COMPONENT_TYPE.CONTENT:
            return (
              <>
                <Form.Label>{dataComponent?.label || "Nội dung"}</Form.Label>
                <Editor
                  apiKey={API_KEY_EDITOR}
                  value={formik?.values?.[dataComponent?.key]}
                  disabled={dataComponent?.properties?.disabled}
                  init={{
                    height: 200,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help \
                    ",
                    entity_encoding: "raw",
                  }}
                  onEditorChange={(value: any) => {
                    formik.setFieldValue(dataComponent?.key, value);
                  }}
                />
              </>
            );
          case COMPONENT_TYPE.HTML:
            // const Htmltag = dataComponent?.tag as keyof JSX.IntrinsicElements;
            return (
              <div className="spaces pt-10 min-w-360">
                {dataComponent?.tag == "p" ?
                  <div > <LabelRequired className="min-w-100px" isRequired={dataComponent?.className == "required"} label={dataComponent?.content || ""} /></div>
                  : <div className="hyperlink">{dataComponent?.label}</div>

                }
              </div>
            );
          default:
            return <></>;
        }
      });
    } else {
      return <></>;
    }
  };

  return (
    <Modal show={true} size="xl" centered>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{listFieldAuto?.title || ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "600px", overflowY: "scroll" }}>
          {renderHTML(listFieldAuto)}
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-fill min-w-80px" type="submit">
            Lưu
          </Button>
          <Button className="btn-secondary min-w-80px" onClick={onCloseClick}>
            Hủy
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GenerateFormComponent;
