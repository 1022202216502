import { useFormikContext } from "formik";
import { FC, useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import ThongTinPhieu from "./ThongTinPhieu";
import ThongTinXuatThuoc from "./ThongTinXuatThuoc";
import { PhieuXuat } from "../../../models/XuatKhoModel";
import WfButton from "../../../../workflows/components/WfButton";
import { WF_TRACKER } from "../../../../utils/Constant";
import FormThemVatTu from "./FormThemVatTu";
import { WMS_STATUS } from "../../../constants/constantsXuatKho";
import { PhanHeXuatKhoContext } from "../../../PhanHeXuatKho";
import { WMS_TYPE } from "../../../constants/constantsXuatKho";

type Props = {
  isReload?: boolean;
  onHide: () => void;
  warehouseExportType?: any;
  warehouseId: number;
};



const FormThemMoi: FC<Props> = (props) => {
  const { warehouseExportType, warehouseId } = props;
  const { onReset, thongTinPhieu, setThongTinPhieu } = useContext(PhanHeXuatKhoContext);
  const { handleSubmit, values } = useFormikContext<PhieuXuat>();
  const [statusPhieu, setStatusPhieu] = useState<any>(false);


  const renderButton = () => {
    if (WMS_TYPE.KHACH_HANG == warehouseExportType?.code) {
      return <>
        {!thongTinPhieu?.phieuXuatKhoAdd?.id &&
          <Button type="submit" className="btn btn-fill min-w-100px spaces mr-10 min-w-100">
            <i className="bi bi-floppy"></i>
            Lưu
          </Button>
        }
      </>
    } else if (WMS_TYPE.NCC == warehouseExportType?.code) {
      return <>
        {/* {thongTinPhieu?.phieuXuatKhoAdd?.id && ( */}
        <WfButton
          tracker={WF_TRACKER.XUAT_KHO}
          objectId={values?.phieuXuatKhoAdd?.id}

          additionalFunc={(data) =>
            setStatusPhieu({
              status: data?.currCode
            })
          }
        />
        {/* )} */}
        {WMS_STATUS.warehouse_approved != statusPhieu?.status &&
          <Button type="submit" className="btn btn-fill min-w-90px spaces mr-10 min-w-100">
            <i className="bi bi-floppy"></i>
            Lưu
          </Button>
        }
      </>
    }
  }

  return (
    <div className="bg-gray">
      <Form className="receive" onSubmit={handleSubmit}>
        <div className="spaces width-100 ">
          <ThongTinPhieu modelID="phieuXuatKhoAdd" warehouseExportType={warehouseExportType} />
          <FormThemVatTu
            statusPhieu={statusPhieu}
            warehouseExportType={warehouseExportType}
            inventoryId={values.phieuXuatKhoAdd?.inventoryId}
            id={values.phieuXuatKhoAdd?.id}
            warehouseId={warehouseId}
          />
          <ThongTinXuatThuoc id={values.phieuXuatKhoAdd?.id} warehouseExportType={warehouseExportType} />
        </div>
        <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6 pl-10">
          {renderButton()}
        </div>
      </Form>
    </div>
  );
};
export default FormThemMoi;
