import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import { useEffect, useState, useContext } from "react";
import { PhanHeXuatKhoContext } from "../../../PhanHeXuatKho";
import CustomIconButton from "../../../../component/custom-icon-button/CustomIconButton";
import { formatDateToDDMMYYYY, formatMoney } from "../../../../utils/FormatUtils";
import { AppContext } from "../../../../appContext/AppContext";
import { deleteVatTuXuatKho, getDsThuocXuatKho } from "../../../services/servicesXuatKho";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, TYPE_WH } from "../../../../utils/Constant";
import { toast } from "react-toastify";

type Props = {
  id: string;
  warehouseExportType?: any
};

const ThongTinXuatThuoc = ({ id, warehouseExportType }: Props) => {
  const { setIsLoading } = useContext(AppContext);
  const { thongTinPhieu, setThongTinPhieu, setVatTuSelected } = useContext(PhanHeXuatKhoContext);

  const getVatTuXuatKho = async () => {
    setIsLoading(true);
    try {
      const { data } = await getDsThuocXuatKho({ type: TYPE_WH.EXPORT, slipId: id });
      if (CODE.SUCCESS === data.code) {
        setThongTinPhieu({
          ...thongTinPhieu,
          dsThuoc: data.data.content,
        })
      }
    } catch (error) {
      toast.error('Lỗi tải danh sách vật tư, vui lòng thử lại')
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {    
    id && getVatTuXuatKho();
  }, [id]);

  const handleDeleteRow = (index: number) => {
    const newDsThuoc = [...(thongTinPhieu.dsThuoc || [])];
    if (index >= 0 && index < newDsThuoc.length) {
      newDsThuoc.splice(index, 1);
    };

    setThongTinPhieu({ ...thongTinPhieu, dsThuoc: newDsThuoc });
  };

  const handleEditRow = (row: any, index?: any) => {
    setVatTuSelected({ ...row, item: row.item?.code, dataItem: row.item, index: index });
  };

  let columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row, index, stt) => {
        return stt
      },
    },
    {
      name: 'TT',
      field: 'custom',
      headerStyle: {
        width: 50,
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (row, index, stt) => {
        return (
          <div className="d-flex gap-5">
            <CustomIconButton
              variant="delete"
              onClick={() => handleDeleteRow(index)}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
            <CustomIconButton
              variant="edit"
              onClick={() => handleEditRow(row, index)}
            >
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>
          </div>
        )
      }
    },
    {
      name: "Mã thuốc",
      field: "code",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => row?.item?.code
    },
    {
      name: "Tên thuốc",
      field: "item",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row, index, stt) => row.item?.name,
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.totalQuantity) || "",
    },
    {
      name: "Đơn vị",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: "105px"
      },
      render: (row, index, stt) => row?.item?.unitofMeasureName,
    },
    {
      name: "Tồn kho",
      field: "iventory",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.iventory),
    },
    {
      name: "Giá bán",
      field: "sellingPrice",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.sellingPrice),
    },
    {
      name: "% VAT",
      field: "phanTramVAT",
      headerStyle: {
        minWidth: "60px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => row?.item?.vat || 0,
    },
    {
      name: "Thành tiền",
      field: "thanhTien",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => {
        const quantity = parseFloat(row?.totalQuantity || 0);
        const price = parseFloat(row?.item?.sellingPrice || 0);
        const vat = parseFloat(row?.item?.vat || 0);
        const result = (quantity * price * (1 + vat / 100));
        return formatMoney(result);
      },
    },
    {
      name: "Số lô",
      field: "consignment",
      headerStyle: {
        minWidth: "75px"
      },
      render: (row, index, stt) => row?.item?.consignment,
    },
    {
      name: "Hạn sử dụng",
      field: "expiryDate",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => formatDateToDDMMYYYY(row?.item?.expiryDate),
    },
  ]
  let columnsNcc: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row, index, stt) => {
        return stt
      },
    },
    {
      name: 'TT',
      field: 'custom',
      headerStyle: {
        width: 50,
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (row, index, stt) => {
        return (
          <div className="d-flex gap-5">
            <CustomIconButton
              variant="delete"
              onClick={() => handleDeleteRow(index)}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
            <CustomIconButton
              variant="edit"
              onClick={() => handleEditRow(row, index)}
            >
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>
          </div>
        )
      }
    },
    {
      name: "Mã thuốc",
      field: "code",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => row?.item?.code
    },
    {
      name: "Tên thuốc",
      field: "item",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row, index, stt) => row.item?.name,
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.totalQuantity) || "",
    },
    {
      name: "Đơn vị",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: "105px"
      },
      render: (row, index, stt) => row?.item?.unitofMeasureName,
    },
    {
      name: "Tồn kho",
      field: "iventory",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.iventory),
    },
    {
      name: "Giá nhập",
      field: "sellingPrice",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.sellingPrice),
    },
    {
      name: "% VAT",
      field: "vat",
      headerStyle: {
        minWidth: "60px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => row?.item?.vat || 0,
    },
    {
      name: "Thành tiền",
      field: "thanhTien",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => {
        const quantity = parseFloat(row?.totalQuantity || 0);
        const price = parseFloat(row?.item?.sellingPrice || 0);
        const vat = parseFloat(row?.item?.vat || 0);
        const result = (quantity * price * (1 + vat / 100));
        return formatMoney(result);
      },
    },
    {
      name: "Số lô",
      field: "consignment",
      headerStyle: {
        minWidth: "75px"
      },
      render: (row, index, stt) => row?.item?.consignment,
    },
    {
      name: "Hạn sử dụng",
      field: "expiryDate",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => formatDateToDDMMYYYY(row?.item?.expiryDate),
    },
  ]
  
  return (
    <div className="bg-white spaces mb-6 pb-15 fl-1">
      <OctTable
        id="h-bang-ds-xuat-thuoc"
        className="spaces table-bang-ds-xuat-thuoc"
        columns={warehouseExportType?.code == "X001" ? columnsNcc : columns}
        data={thongTinPhieu?.dsThuoc || []}
        justFilter={false}
        clearToolbar
        noToolbar
        noPagination
      />
    </div>
  );
};

export default ThongTinXuatThuoc;
