import { Button, Modal } from "react-bootstrap";
import { generateForm } from "../../utils/AppFunction";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { PhanHeNhapKhoContext } from "../PhanHeNhapKho";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { addPhieuNhapKho, checkNewItem, getDsThuocNhapKho, updatePhieuNhapKho } from "../services/services";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, TYPE_WH } from "../../utils/Constant";
import { toast } from "react-toastify";
import WfButton from "../../workflows/components/WfButton";
import TableThongTinNhapThuoc from "./TableThongTinNhapThuoc"
import { WMS_STATUS } from "../constants/constansNhapKho";
import FormThemVatTu from "./FormThemVatTu";
import { IconButtonSave } from "../../component/IconSvg";

interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  setInitialValues: Dispatch<SetStateAction<any>>
  warehouseId?: any;
}

const DialogPhieuNhapKho = (props: Props) => {
  const { show, onHide, warehouseId, setInitialValues, initialValues } = props;
  const { setIsLoading } = useContext(AppContext);
  const { dsNhapKho, setDsNhapKho, detailPhieuNhap, setDetailPhieuNhap } = useContext(PhanHeNhapKhoContext);
  const intl = useIntl();
  const isView = detailPhieuNhap?.status === WMS_STATUS.warehouse_approved;
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [dataVatTu, setDataVatTu] = useState<any>({});
  const [initialValuesFormThemVT, setInitialValuesFormThemVT] = useState<any>({});
  const [indexRowEdit, setIndexRowEdit] = useState<number | null>(null);

  const validationSchema = Yup.object({
    nhapKho: Yup.object({
      receWarehouse: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      doCreation: Yup.date().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      inlsendDate: Yup.date().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      supplier: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
    })
  });

  const validationSchemaFormThemVT = Yup.object({
    nhapKho: Yup.object({
      // columnsColumns2Text3: Yup.object().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    })
  });

  useEffect(() => {
    const quantity = parseFloat(dataVatTu?.totalQuantity) || 0;
    const price = dataVatTu.sellingPrice || 0;
    const vat = dataVatTu.vat || 0;
    setDataVatTu({
      ...dataVatTu,
      totalAmount: (quantity * price * (1 + vat / 100)).toFixed(2),
    })
  }, [dataVatTu?.totalQuantity, dataVatTu?.sellingPrice, dataVatTu?.vat]);

  const updateForm = async () => {
    const data = await generateForm("phieuNhapKhoAdd", setIsLoading);
    setListJsonGenerate(data || []);
  };
  const handleAddItem = async (values: any) => {
    const dataItem = values.themVatTuPhieuNhapKho || {};
    const message = !dataItem.code
      ? 'Chưa chọn vật tư'
      : !dataItem.totalQuantity
      ? 'Chưa nhập số lượng'
      : dataItem.totalQuantity <= 0
      ? 'Số lượng phải lớn hơn 0'
      : !dataItem.consignment || !dataItem.biddingPackage || !dataItem.biddingGroup || !dataItem.biddingYear || !dataItem.serialNumber || !dataItem.expiryDate
      ? 'Chưa nhập đủ thông tin bắt buộc' : null;

    if (message) {
      toast.warning(message);
      return;
    };

    const { data } = await checkNewItem({
      serialNumber: dataItem.serialNumber,
      itemId: dataItem.itemId,
      servicePrice: dataItem.servicePrice || 0,
      sellingPrice: dataItem.sellingPrice || 0,
      expiryDate: dataItem.expiryDate,
      bhytPrice: dataItem.bhytPrice || 0,
      hospitalPrice: dataItem.hospitalPrice || 0,
    });

    if (CODE.SUCCESS !== data.code) {
      toast.error(data.message);
      return;
    };

    let newLstItem = [...dsNhapKho];
    if (indexRowEdit === null) {
      newLstItem = [...newLstItem, { ...dataItem, item: dataItem.item || dataItem, id: null, warehouseId: warehouseId }];
    } else {
      newLstItem[indexRowEdit] = { ...dataItem, item: dataItem.item || dataItem, id: newLstItem[indexRowEdit].id, warehouseId: warehouseId };
    }
    setIndexRowEdit(null);
    setInitialValuesFormThemVT({ randomValues: Math.random() });
    setDsNhapKho(newLstItem);
  };
  const handleSubmit = async (values: any) => {
    if (!dsNhapKho?.length) {
      toast.warning('Chưa có vật tư nhập kho');
      return;
    };
    const dataSubmit = {
      slipDto: {
        ...values?.nhapKho,
        type: TYPE_WH.IMPORT,
        warehouseId: warehouseId,
      },
      lstItem: [...(dsNhapKho || [])].map(item => {
        return (
          {
            itemId: item?.item?.id,
            totalQuantity: parseFloat(item.totalQuantity ?? "0"),
            ...item?.item,
            ...item,
            sellingPrice: parseFloat(item.sellingPrice),
            totalAmount: parseFloat(item.totalAmount ?? "0"),
            warehouseId: warehouseId,
            pwVat: parseFloat((item.importPrice * (1 + item.vat / 100)).toFixed(0)),
            id: item?.id || null
          }
        )
      }),
    };
    try {
      setIsLoading(true);
      const id = values.nhapKho?.id;
      const { data } = id ? await updatePhieuNhapKho(id, dataSubmit) : await addPhieuNhapKho(dataSubmit);
      if (CODE.SUCCESS === data?.code) {
        setInitialValues(data?.data);
        setDetailPhieuNhap(data?.data);
        getSelectedRowsData(data?.data?.id);
        toast.success(((id) ? "Cập nhật" : "Thêm") + " phiếu nhập kho thành công");
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
        setIsLoading(false);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    }
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getDsThuocNhapKho({ pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, slipId: id });
      setDsNhapKho(data?.data?.content || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialValues?.nhapKho?.id && getSelectedRowsData(initialValues?.nhapKho?.id || detailPhieuNhap?.id);
  }, [initialValues?.nhapKho?.id]);

  useEffect(() => {
    updateForm();
  }, []);

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, setValues, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
                <div className="spaces mt-4 title">Thêm phiếu nhập kho</div>
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10">
                <GenerateFormComponent
                  isView={isView}
                  listFieldAuto={listJsonGenerate}
                  handleChange={handleChange}
                  setValues={setValues}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  modelID={"nhapKho"}
                />
              </div>
              <div className="spaces fw-bold"> <hr /></div>
              <div className="spaces px-10">
                <Formik
                  enableReinitialize
                  initialValues={{ themVatTuPhieuNhapKho: initialValuesFormThemVT }}
                  validationSchema={validationSchemaFormThemVT}
                  onSubmit={handleAddItem}
                >
                  <FormThemVatTu warehouseId={warehouseId} isView={isView} indexRowEdit={indexRowEdit} />
                </Formik>
              </div>
              <TableThongTinNhapThuoc
                isView={isView}
                setInitialValuesFormThemVT={setInitialValuesFormThemVT}
                setIndexRowEdit={setIndexRowEdit}
              />
            </Modal.Body>
            <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6 pl-5">
              {(values.nhapKho?.id || detailPhieuNhap?.id) && (
                <WfButton
                  additionalFunc={(data) =>
                    setDetailPhieuNhap({
                      ...detailPhieuNhap,
                      status: data?.currCode
                    })
                  }
                  tracker="warehouse_receipt"
                  objectId={values.nhapKho?.id || detailPhieuNhap?.id}
                />
              )}
              {WMS_STATUS.warehouse_approved != detailPhieuNhap?.status &&
                <Button type="submit" className="btn btn-fill min-w-100px spaces ml-5">
                  <IconButtonSave /> Lưu
                </Button>
              }
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DialogPhieuNhapKho;
