import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import PhanHeQuanTriHeThong from "../modules/phan-he-quan-tri-he-thong/PhanheQuanTriHeThong";
import PhanHeThongKe from "../modules/phan-he-thong-ke/PhanHeThongKe";
import PhanHeNhapKho from "../modules/phan-he-nhap-kho/PhanHeNhapKho";
import PhanHeXuatKho from "../modules/phan-he-xuat-kho/PhanHeXuatKho";
import { HomePage } from "../pages/Homepage/HomePage";
import { MenuTestPage } from "../pages/MenuTestPage";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import PhanHeDieuChuyen from "../modules/phan-he-dieu-chuyen/PhanHeDieuChuyen";
import QuanLyThau from "../modules/quan-ly-thau/QuanLyThau";
import PhanHeTuTruc from "../modules/phan-he-tu-truc/PhanHeTuTruc";
import KhoVatTu from "../modules/kho-vat-tu-ngoai-tru/KhoVatTu";
import KhoNhaThuoc from "../modules/kho-nha-thuoc/KhoNhaThuoc";
import PhanHeVienPhi from "../modules/phan-he-thu-ngan/PhanHeThuNgan";
import TheKho from "../modules/the-kho/TheKho"

const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));

  return (

    <Routes>
      <Route index element={<Navigate to="/phan-he-thong-ke" />} />
      <Route path="/*" element={<Navigate to="/phan-he-thong-ke"/>} />
      <Route path="home" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/home" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} /> 
        <Route path="phan-he-thu-ngan" element={<PhanHeVienPhi />} />
        <Route path="quan-tri-he-thong" element={<PhanHeQuanTriHeThong />} />
        <Route path="phan-he-thong-ke" element={<PhanHeThongKe />} />
        {/* <Route path="phan-he-nhap-kho" element={<PhanHeNhapKho />} /> */}
        {/* <Route path="phan-he-xuat-kho" element={<PhanHeXuatKho />} /> */}
        <Route path="the-kho" element={<TheKho />} />
        <Route path="phan-he-tu-truc" element={<PhanHeTuTruc />} />
        <Route path="quan-ly-thau" element={<QuanLyThau />} />
        <Route path="kho-vat-tu-ngoai-tru" element={<KhoVatTu />} />
        <Route path="kho-nha-thuoc" element={<KhoNhaThuoc />} />
        {/* Lazy Modules */}
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>

  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

