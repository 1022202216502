import React, { ChangeEvent } from "react";
import { ActionMeta, MenuPlacement } from "react-select";
import { Placement } from "../tooltip";

export interface ICommonAutoCompleteProps {
  options: any[];
  horizontal?: boolean;
  isClearable?: boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  searchFunction?: (searchObject: any) => Promise<any> | null | 0 | undefined;
  searchObject?: any | undefined;
  onChange?: ((newValue: any, actionMeta?: ActionMeta<any>) => void) | undefined;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  className?: string;
  name?: string;
  id?: string;
  key?: string;
  noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode;
  backspaceRemovesValue?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  placeholder?: string;
  value?: any;
  dependencies?: any[];
  touched?: any;
  errors?: any;
  disabled?: boolean;
  handleClearValue?: any;
  urlData?: string;
  showCode?: boolean;
  displayLable?: string;
  menuPortalTarget?: any;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  defaultValue?: any;
  menuPlacement?: MenuPlacement | undefined;
  styles?: any;
  isReadOnly?: boolean;
  lable?: string;
  isRequired?: boolean;
  valueSearch?: string | number;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  sort?: (data: any) => void;
  customFilterOption?: (option: any, inputValue: any) => void;
  renderChilden?: string;
  hideLabel?: boolean;
}

interface ITooltip {
  isTooltip?: true;
  titleTooltip?: string;
  placementTooltip?: Placement;
  delayTooltip?: number;
}

interface ILazyLoading {
  isScroll?: boolean;
  labelSearch?: string;
}

interface IFormCheckBox {
  formCheckBox?: boolean;
  isCheckBox?: boolean;
  setIsCheckBox?: (isCheck: boolean) => void;
}

// add new
interface IYesAddNew {
  isAddNew: true;
  onAddNew: () => void;
}

interface INoAddNew {
  isAddNew?: false;
}

type IAddNew = | IYesAddNew | INoAddNew;

export type IAutoCompleteProps = ICommonAutoCompleteProps & ITooltip & ILazyLoading & IFormCheckBox & IAddNew

export enum TYPE {
  OBJECT = "object",
  STATUS = "status",
  STRING = "string",
  TEXT = "text",
  NUMBER = "number",
  INPUT = "input",
  DATE = "date",
  TEXTAREA = "textarea",
  SELECT = "select",
  SINGLE = "single",
  MULTILINE = "multiline",
  MODULE = "module",
  PASSWORD = "password",
  EXCEL = "EXCEL",
  WORD = "WORD",
  MONEY = "MONEY",
  NUMBER_FLOAT = "NUMBER_FLOAT"
}

export const KEY = {
  ENTER: 'Enter',
  SPACE: 'Space',
}