export const HEADER_TABLE_DMDVG = [
  {
    key:"action",
    value: "Thao tác"
  },
  {
    key:"name",
    value: "Tên dịch vụ"
  },
  {
    key:"code",
    value: "Mã dịch vụ"
  },
  {
    key:"servicePrice",
    value: "Giá dịch vụ"
  },
  {
    key:"insurancePrice",
    value: "Giá bảo hiểm"
  }
]

export const MESSAGE_SUCCESS = {
  addService: "Thêm dịch vụ thành công",
  updateService: "Sửa dịch vụ thành công",
  deleteService: "Xóa dịch vụ thành công",
}
