import clsx from 'clsx';
import Fuse from 'fuse.js';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Form, Overlay, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { RESPONSE_MESSAGE } from '../../utils/Constant';
import { COLUMNS, COLUMNS_NHAP_KHO } from './Column';
import TableSelect from './TableSelect';
import { searchVatTuNhapKho, searchVatTuXuatKho } from './services';

type Props = {
	warehouseId?:any;
	values?: any;
	value?: any;
	DynamicClass?: any
	maKhoThuoc?: string;
	setThuocSelected?: (value: any) => void;
	label?: string;
	containerClassName?: string;
	inputClassName?: string;
	labelClassName?: string;
	initialDanhSachThuoc?: any[];
	isNhapKho?: boolean;
};

const SelectTableButton = ({
	values,
	warehouseId,
	DynamicClass = "pl-20",
	value,
	maKhoThuoc,
	setThuocSelected = () => { },
	containerClassName,
	inputClassName,
	labelClassName,
	label,
	initialDanhSachThuoc,
	isNhapKho = false,
	...props
}: Props) => {
	
	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const initialTableDataRef = useRef<any>([]);
	const selectedRowRef = useRef<any>();

	const [tableData, setTableData] = useState<any>([]);
	const [isShow, setIsShow] = useState<boolean>(false);
	const [query, setQuery] = useState<string>('');
	const [selectedIndex, setSelectedIndex] = useState<number>(-1);

	useEffect(() => {
		window.addEventListener('click', checkMouseClickOutSide, true);

		return () => {
			window.removeEventListener('click', checkMouseClickOutSide, true);
		};
	}, []);

	useEffect(() => {
		setQuery(values?.name);
		if (!values?.name) {
			selectedRowRef.current = null;
			setTableData(initialTableDataRef.current);
		}
	}, [values?.name]);

	useEffect(() => {
		getDanhSachThuoc();
	}, [warehouseId]);

	useEffect(() => {
		if (value) {
			const selectedRow = tableData?.find((item: any) => item?.code === value);
			setTableData(selectedRow ? [selectedRow] : tableData);
		}
	}, [value]);

	const checkMouseClickOutSide = (e: MouseEvent) => {
		if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
			setIsShow(false);
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		setQuery(inputValue);

		if (!inputValue) {
			setTableData(initialTableDataRef.current);
			setSelectedIndex(-1);
			return;
		}

		const fuse = new Fuse(initialTableDataRef.current, {
			keys: ['code', 'name', 'actIngName', 'dosage'],
			includeScore: true,
			includeMatches: true,
			ignoreLocation: true,
		});
		const result = fuse.search(inputValue).map(record => record.item);
		setTableData(result);
	};

	const getDanhSachThuoc = async () => {
		try {
			let { data } = isNhapKho
				? await searchVatTuNhapKho({ warehouseId })
				: await searchVatTuXuatKho({ warehouseId:  warehouseId, pageIndex: 1, pageSize: 100_000 });
			
			setTableData(data?.data?.content || data?.data || []);
			initialTableDataRef.current = data?.data?.content || data?.data || [];
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onRowSelect = (rowData: any) => {
		if (!rowData) return;
		selectedRowRef.current = rowData;
		setThuocSelected({
			...rowData,
			roa: rowData.ROAName,
		});
		setSelectedIndex(0);
		setIsShow(false);
	};

	const rowSelected = tableData[selectedIndex];

	const onContainerKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		const dataLength = tableData.length;

		switch (e.key) {
			case 'ArrowDown':
				if (!isShow) {
					setIsShow(true);
					return;
				}
				setSelectedIndex(selectedIndex === dataLength - 1 ? 0 : selectedIndex + 1);
				break;

			case 'ArrowUp':
				setSelectedIndex(selectedIndex === 0 ? dataLength - 1 : selectedIndex - 1);
				break;

			case 'Enter':
				e.preventDefault();
				onRowSelect(rowSelected);
				break;

			case 'Escape':
			case 'Tab':
				setIsShow(false);
				break;

			default:
				break;
		}
	};

	const renderTable = () => {
		return (
			<Popover
				style={{
					minWidth: inputRef.current?.offsetWidth,
				}}
				className="input-autocomplete-popover"
			>
				<Popover.Body className="p-8 spaces">
					<TableSelect
						data={tableData}
						columns={isNhapKho ? COLUMNS_NHAP_KHO : COLUMNS}
						containerClassName="spaces max-h-250"
						selectedRowIndex={selectedIndex}
						onSelect={row => {
							onRowSelect(row);
						}}
					/>
				</Popover.Body>
			</Popover>
		);
	};

	return (
		<div className={containerClassName} ref={containerRef} onKeyDown={onContainerKeyDown}>
			<div className={`d-flex spaces align-items-center mt-7 ${DynamicClass}`}>
				<Form.Control
					className={clsx('spaces px-4 customs-input', inputClassName)}
					ref={inputRef}
					value={query || ""}
					onChange={onSearch}
					onFocus={() => {
						setIsShow(true);
					}}
					{...props}
				/>
			</div>

			<Overlay
				placement="bottom-start"
				target={inputRef.current}
				container={containerRef.current}
				offset={[0, 3]}
				show={isShow}
			>
				{renderTable()}
			</Overlay>
		</div>
	);
};

export default SelectTableButton;
