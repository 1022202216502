import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { DanhSachPhieuInterface } from "../models/NhapKhoModel"
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { formatDateAdvanceToString, formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";

export const DanhSachPhieuColumns: ReadonlyArray<
  Column<DanhSachPhieuInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "doCreation",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Số HĐ"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "invoiceNumber",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.invoiceNumber}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày gửi HĐ"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "inlsendDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.inlsendDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Nhà cung cấp"
          className="text-center text-white align-middle bg-pri min-w-125px"
        />
      ),
      id: "supplierName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.supplierName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Nhập kho"
          className="text-left text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "receWarehouseName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.receWarehouseName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Người giao"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "deliverer",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.deliverer}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Người nhận"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "recipient",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.recipient}
        />
      ),
    },
  ];
export const DanhSachNhapThuocColumns: ReadonlyArray<
  Column<DanhSachPhieuInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Tên thuốc"
          className=" text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "tenThuoc",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.item?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="ĐVT"
          className=" text-center text-white align-middle bg-pri min-w-50px"
        />
      ),
      id: "dvt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.item?.unitofMeasure}
          className="text-left"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Quy cách đóng gói"
          className=" text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "quyCach",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.item?.packaging}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Số thầu"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "soThau",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.soThau}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="SL nhập"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "slNhap",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.quanReceived)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="SL quy đổi"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "slQuyDoi",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.slQuyDoi)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Số lô nhập"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "soLoNhap",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.consignment}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày hết hạn"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "ngayHetHan",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.expiryDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Đơn giá"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "donGia",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.unitPrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Giá quy đổi"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "giaQuyDoi",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.giaQuyDoi)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Giá nhập kho"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "giaNhapKho",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.purchasePrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Giá bán"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "giaBan",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.sellingPrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="VAT"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "vat",
      Cell: ({ ...props }) => {
        const row = props.data[props.row.index] || {};
        return (
          <TableCustomCell
            className="text-end s-2"
            data={formatMoney(row.giaNhapKho * row.vat / 100)}
          />
        )
      }
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Thành tiền"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "thanhTien",
      Cell: ({ ...props }) => {
        const row = props.data[props.row.index] || {};
        return (
          <TableCustomCell
            className="text-end s-2"
            data={formatMoney(row.giaNhapKho * row.slQuyDoi + (row.giaNhapKho * row.vat / 100))}
          />
        )
      }
    },
  ];

export const summaryStatus = [
  {
    statusName: 'Chờ duyệt',
    summary: 3,
    color: '#28A745',
  },
  {
    statusName: 'Đã duyệt',
    summary: 3,
    color: '#DC3545',
  }
]