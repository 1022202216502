import { useContext, useEffect, useState } from "react";
import QuanLyKho from "../../component/quan-ly-kho/QuanLyKho";
import { AppContext } from "../../appContext/AppContext";
import { searchDSTuTruc } from "../services/TuTrucServices";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../utils/Constant";
import { toast } from "react-toastify";
import CustomDropdownButtonChoose from "../../component/CustomDropdownButtonChoose";


const TonKhoTuTruc: React.FC = (props) => {
  const { setIsLoading } = useContext(AppContext);
  const [dsTuTruc, setDsTuTruc] = useState([]);
  const [idSelected, setIdSelected] = useState<number>();

  const handleSelectedItem = (item: any) => {
    setIdSelected(item?.id);
  };

  const updateData = async () => {
    try {
      setIsLoading(true);
      const { data } = await searchDSTuTruc({ pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX });
      if (CODE.SUCCESS === data.code) {
        setDsTuTruc(data.data.content);
        setIdSelected(data.data.content[0]?.id);
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại')
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  useEffect(() => {
    updateData();
  },[]);

  return (
      <QuanLyKho
        warehouseId={idSelected}
        customTitle={
          <>
            <h4 className="text-title text-pri fw-bold fs-4 max-content">Danh sách vật tư</h4>
            <CustomDropdownButtonChoose
              listMenuItems={dsTuTruc}
              label="Chọn tủ trực"
              onClickItem={handleSelectedItem}
            />
          </>
        }
      />
  )
};

export default TonKhoTuTruc;