import { INV_TYPE_CODE } from "../../phan-he-thu-ngan/const/ThuNganConstant";

export const EXPORT_TYPE = {
    PDF: "pdf",
    DOC: "doc"
}

export const MAP_EXPORT_PATH = {
    [INV_TYPE_CODE.PHIEU_THU.code]: "phieu_thu",
    [INV_TYPE_CODE.PHIEU_TAM_UNG.code]: "tam_ung",
    [INV_TYPE_CODE.PHIEU_HOAN.code]: "phieu_hoan",
    PHIEU_CHI_DINH_KHAM_BENH: "PHIEU_CHI_DINH_KHAM_BENH",
};