import { Button, Modal } from "react-bootstrap";
import { generateForm, generateHrTag } from "../../utils/AppFunction";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, SELECTION_MODE, TYPE_TU_TRUC, TYPE_WH, WF_TRACKER } from "../../utils/Constant";
import { toast } from "react-toastify";
import { PhanHeTuTrucContext } from "../PhanHeTuTruc";
import { formatDateDTO } from "../../utils/FormatUtils";
import { createPhieuBoSungCoSoTuTruc, createPhieuLinh, createPhieuXuatSD, getDsVatTuPhieu, updateDsVatTuTuTruc, updatePhieuBoSungCoSoTuTruc, updatePhieuLinh, updatePhieuXuatSD } from "../services/TuTrucServices";
import WfButton from "../../workflows/components/WfButton";
import '../PhanHeTuTruc.scss';
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { summaryStatus, TU_TRUC } from "../constants/TuTrucConstans";
import { TMCoSoTuTrucColumns } from "../constants/TuTrucColumns";
import moment from "moment";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { RESPONSE_CODE_SUCCESS } from "../../category-personnel/const/PersonnelConst";
import useMultiLanguage from "../../../hook/useMultiLanguage";

interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  modelId: any;
}

interface InventoryItem {
  iventory: number;
  code: string;
  unitofMeasure: string;
  materialGroup: number;
  materialType: string;
  name: string;
  peShipment: number;
  id: number;
  unitofMeasureName: string;
}

const DialogThemPhieuTuTruc = (props: Props) => {
  const { show, onHide, initialValues, modelId } = props;
  const { setIsLoading } = useContext(AppContext);
  const { tuTrucCurrent , dsVatTu, setDsVatTu } = useContext(PhanHeTuTrucContext);
  const { lang } = useMultiLanguage();
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [initValues, setInitValues] = useState<any>({} as any);
  const [medicine, setMedicine] = useState<InventoryItem>({} as InventoryItem)
  const [lstItem, setLstItem] = useState<any>([])
  const [itemSelected, setItemSelected] = useState<any>({} as any)
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false)
  const [idItem, setIdItem] = useState('');
  const [statusPhieu, setStatusPhieu] = useState<any>(false);
  const setFieldValueRef = useRef<any>()
  const formRef = useRef<any>()
  const valueRef = useRef<any>()

  const mappingType = {
    [TYPE_WH.BO_SUNG_CO_SO_TU_TRUC]: WF_TRACKER.BO_SUNG_CO_SO_TU_TRUC,
    [TYPE_WH.PHIEU_LINH]: WF_TRACKER.XUAT_KHO
  }
  const caculatorTotalAmount = (values: any) => Number(values.totalQuantity) * (Number(values.sellingPrice) + ((Number(values.vat) / 100) * Number(values.sellingPrice)))
  const autoGenerateTracker = (type: number) => mappingType[type]

  useEffect(() => {
    generateHrTag('#kt_app_body > div.fade.page-full.spaces.h-calc-vh-50.modal.show > div > div > form > div.spaces.p-0.bg-white.modal-body > div > form > div:nth-child(1)')
  }, [listJsonGenerate])

  useEffect(() => {
    const formNode = formRef.current;

    const handleBlur = (event: any) => {
      setFieldValueRef.current(modelId.id,
        {
          ...valueRef.current,
          totalAmount: caculatorTotalAmount(valueRef.current)
        })
    };

    if (formNode) {
      formNode.addEventListener('focusout', handleBlur);
    }

    return () => {
      if (formNode) {
        formNode.removeEventListener('focusout', handleBlur);
      }
    };
  }, []);

  useEffect(() => {
    if (modelId.type === TYPE_WH.BO_SUNG_CO_SO_TU_TRUC) {
      return setInitValues({ [modelId.id]: { ...initialValues[modelId.id], code: '', whReceive: tuTrucCurrent } })
    }
    return setInitValues(initialValues)
  }, [modelId])

  useEffect(() => {
    setFieldValueRef.current(modelId.id,
      initialValues[modelId.id]?.id ? {
        ...itemSelected,
        author: initialValues[modelId.id].author,
        whReceive: tuTrucCurrent,
        whIssue: initialValues[modelId.id].whIssue,
        notes: initialValues[modelId.id].notes,
      } : {
        ...itemSelected,
      })
  }, [itemSelected])

  const validationSchema = Yup.object({
    [modelId.id]: Yup.object({
      // columnsColumns2Text3: Yup.object().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    })
  })

  const updateForm = async () => {
    const data = await generateForm(modelId.id, setIsLoading);
    setListJsonGenerate(data || []);
  };

  const handleSubmit = async (values: any) => {
    if(modelId.type === TYPE_WH.BO_SUNG_CO_SO_TU_TRUC) return handlerSubmitBSCoSoTuTruc(values)
    setIsLoading(true);
    try {
      const id = values[modelId.id]?.id;
      const warehouseId = values[modelId.id]?.whIssueId;
      const listItems = [...dsVatTu].map(item => ({
        itemId: item?.item?.id,
        totalQuantity: parseFloat(item.totalQuantity),
        warehouseId: warehouseId,
        lstDetail: [
          {
            quantity: parseFloat(item.totalQuantity),
            conversionQuantity: parseFloat(item.totalQuantity),
            binId: warehouseId,
            inputDate: formatDateDTO(new Date()),
            itemId: item?.item?.id,
            itemPrice: item?.item?.purchasePrice,
          }
        ]
      }));

      const dataSubmit = {
        slipDto: {
          ...values[modelId.id],
          type: modelId.type,
        },
        lstItem: listItems,
      };

      const { data } = modelId.type === TYPE_WH.PHIEU_LINH
        ? (id ? await updatePhieuLinh(id, dataSubmit) : await createPhieuLinh(dataSubmit))
        : (id ? await updatePhieuXuatSD(id, dataSubmit) : await createPhieuXuatSD(dataSubmit))

      if (id) {
        const res = await updateDsVatTuTuTruc({
          slipId: id,
          type: modelId.type,
          lstItem: listItems,
        });
        if (CODE.SUCCESS !== res.data?.code) {
          toast.error('Lỗi cập nhật danh sách vật tư, vui lòng thử lại')
          return;
        }
      }

      if (CODE.SUCCESS === data.code) {
        toast.success(`${values[modelId.id].id ? 'Cập nhật' : 'Thêm mới'} ${modelId.title} thành công`)
        onHide();
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getDsVatTuPhieu({ pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, slipId: id, type: modelId.title ? 1 : 2 }); // 1 tủ trực 2 kho
      if (CODE.SUCCESS === data.code) {
        setLstItem([...data.data.content].map(values => ({...values, ...values.item, id: values.id, unitOfMeasureName: values.item.unitofMeasureName })));
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialValues[modelId.id]?.id && getSelectedRowsData(initialValues[modelId.id]?.id);
  }, [initialValues[modelId.id]?.id]);

  useEffect(() => {
    updateForm();
  }, []);

  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center ">
        <>
          <CustomIconButton
            variant="edit"
            onClick={() => {
              setItemSelected({ ...props.cell.row.original });
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>

          {modelId.title && <CustomIconButton
            variant="delete"
            onClick={() => {
              setIdItem(props.cell.row.original.id ? props.cell.row.original.id : props.cell.row.original.idFake);
              setOpenDialogConfirmDelete(true);
            }}
          >
            <i className="bi bi-trash3-fill text-danger"></i>
          </CustomIconButton>
          }
        </>
      </div>
    )
  };

  const handlerMedicine = (value?: any, values?: any, setFieldValue?: any) => {
    setMedicine(value);
    let obj = {
      ...values.themphieubosungcosotutruc,
      iventory: Number(value.iventory),
      unitOfMeasureName: value.unitofMeasureName,
      code: value.code,
      CountryOfOrigin: null, //Nước sản xuất
      manufacturer: null, //Hãng sản xuất
      consignment: Number(value.materialGroup), // Số lô
      specification: null, //Quy cách
      name: value.name,
      itemId: value.id,
      expiryDate: moment(values?.expiryDate).format("YYYY-MM-DDTHH:mm:ss")
    }
    delete obj?.[modelId.id];
    setFieldValue(modelId.id, obj)
  }

  const generateSlipDto = (values:any) =>
    ({
    ...values[modelId.id],
    idFake: new Date(),
    sellingPrice: Number(values[modelId.id].sellingPrice) || 0,
    totalQuantity: Number(values[modelId.id].totalQuantity) || 0,
    totalAmount: caculatorTotalAmount(values[modelId.id]),
    vat: Number(values[modelId.id].vat) || 0,
    whReceiveName: values[modelId.id].whReceive.whReceiveName,
    whReceive: values[modelId.id].whReceive.whReceiveCode,
    whReceiveId: values[modelId.id].whReceive.id,
    type: TU_TRUC.BO_SUNG_CO_SO // bổ sung cơ số
  })

  const handlerSubmitBSCoSoTuTruc = async (values: any) => {
    try {
      setIsLoading(true)
      const data = {
        slipDto: generateSlipDto(values),
        lstItem: lstItem.map((item: any) => {
          item.iventory = Number(item.iventory)
          item.totalAmount = caculatorTotalAmount(item)
          return item
        })
      };
      let res = initialValues[modelId.id]?.id ?
        await updatePhieuBoSungCoSoTuTruc(initialValues[modelId.id]?.id, data) :
        await createPhieuBoSungCoSoTuTruc(data);
      if(res?.data?.code === RESPONSE_CODE_SUCCESS) {
        toast.success("Thàng công");
        onHide()
      }
    } catch (error) {
      console.error("ERR", error)
    } finally {
      setIsLoading(false)
    }
  }

  const handlerAddWithJs = (values: any, setFieldValue: any) => {
    if(itemSelected.idFake || itemSelected.id) { // Cập nhật
      let arr = lstItem.map((item:any) => {
        if(item.idFake !== itemSelected.idFake || item.id !== itemSelected.id) return item;
        return { ...itemSelected, ...values[modelId.id] }
      })
      setItemSelected({})
      setLstItem(arr)
    }

    let keys = Object.keys(values[modelId.id]);
    keys.forEach((key: string) => {
      if(["author", "whReceive", "notes", "whIssue"].includes(key)) return;
      setFieldValue(`${modelId.id}.${key}` , '')
    })
    !itemSelected.idFake && !itemSelected.id && setLstItem([...lstItem, generateSlipDto(values)])
  }

  const handleDeletePhieu = () => {
    let arr = [...lstItem.filter((item:any) => ![item.idFake, item.id].includes(idItem))]
    setLstItem(arr)
    setItemSelected({})
    setOpenDialogConfirmDelete(false)
  }

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue, setValues }) => {
          setFieldValueRef.current = setFieldValue;
          valueRef.current = values[modelId.id]
          return <Form ref={formRef} onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
                {
                  modelId.title &&
                  <div className="spaces mt-4 title">
                    {`${values[modelId.id]?.id ? 'Cập nhật' : 'Thêm mới'} ${modelId.title}`}
                  </div>
                }
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10 mb-10">
                <h4 className="text-title fw-bold fs-5 spaces mb-5">Thông tin phiếu</h4>
                <GenerateFormComponent
                  listFieldAuto={listJsonGenerate}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  propsRenderButton={(e: any) => handlerMedicine(e, values, setFieldValue)}
                  modelID={modelId.id}
                  customComponent={DynamicComponent}
                />
                {
                  modelId.type === TYPE_WH.BO_SUNG_CO_SO_TU_TRUC && modelId.title &&
                  <Button className="btn btn-fill custom-position-btn spaces min-w-90px mt-10" onClick={() => handlerAddWithJs(values, setFieldValue)}>
                    {(itemSelected.idFake || itemSelected.id) ? 'Cập nhật' : 'Thêm'}
                  </Button>
                }
              </div>
              <div className=" spaces bg-white mb-0">
                <TableCustom<any>
                  className="spaces h-calc-vh-210"
                  data={lstItem}
                  columns={TMCoSoTuTrucColumns}
                  columnsActions={columnsActions}
                  verticalScroll={true}
                  selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                />
                <TablePagination
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={rowsPerPage}
                  rowsForPage={rowsForPage}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  totalPages={configTable.totalPages}
                  totalElements={configTable.totalElements}
                  numberOfElements={configTable.numberOfElements}
                  hideTotalElements
                  customComponent={<WfStatusSummary hiddenQuantity summaryStatus={summaryStatus} col={3} />}
                  customComponentClassName="min-w-600px"
                  className="border border-0"
                />
              </div>
            </Modal.Body>
            <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6">
              <WfButton
                tracker={autoGenerateTracker(modelId.type)}
                objectId={values[modelId.id]?.id}
                additionalFunc={(data) => {
                  setStatusPhieu({
                    status: data?.currCode
                  })
                }}
              />
              {WF_TRACKER.BO_SUNG_CO_SO_TU_TRUC != statusPhieu?.status && modelId.title &&
                <Button type="submit" className="btn btn-fill min-w-90px spaces ms-5">
                  Lưu
                </Button>
              }
            </div>
          </Form>
        }}
      </Formik>
      {openDialogConfirmDelete &&
          <ConfirmDialog
            show={openDialogConfirmDelete}
            onCloseClick={() => setOpenDialogConfirmDelete(false)}
            onYesClick={handleDeletePhieu}
            title="Xóa phiếu"
            message={`Bạn chắc chắn muốn xóa ?`}
            yes="Xóa"
            close="Hủy"
          />
        }
    </Modal>
  );
};

export default DialogThemPhieuTuTruc;
