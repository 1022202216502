export const summaryStatus = [
  {
    statusName: 'Chờ duyệt',
    summary: 0,
    color: '#28A745',
  },
  {
    statusName: 'Đã duyệt',
    summary: 0,
    color: '#DC3545',
  }
];

export const WMS_STATUS = {
  warehouse_approved: "warehouse_approved",
};

export const WMS_TYPE = {
  KHACH_HANG:"X002",
  NCC:"X001",
}

export const WMS_CODE = [
  { code: "", name: "Xuất kho vật tư", modelId: "phieuXuatKhoAdd", modelIdVT: "formThemMoiVatTuXuatKho" },
  { code: "X001", name: "Xuất nhà cung cấp", modelId: "themMoiPhieuXuatKhoNCC", modelIdVT: "formThemMoiVatTuXuatKhoNCC" },
  { code: "X002", name: "Xuất khách hàng", modelId: "themMoiPhieuXuatKhoKH", modelIdVT: "formThemMoiVatTuXuatKhoKH" }
];