import { Button, Modal } from "react-bootstrap";
import { generateForm } from "../../utils/AppFunction";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { CODE } from "../../utils/Constant";
import { toast } from "react-toastify";
import { QuanLyThauContext } from "../QuanLyThau";
import { addGoiThau, updateGoiThau } from "../services/QuanLyThauServices";


interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
}

const DialogThemMoiGoiThau = (props: Props) => {
  const { show, onHide, initialValues } = props;
  const { setIsLoading } = useContext(AppContext);
  const { detailGoiThau, setDetailGoiThau } = useContext(QuanLyThauContext);
  const intl = useIntl();
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);

  const validationSchema = Yup.object({
    goiThau: Yup.object({
      // columnsColumns2Text3: Yup.object().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    })
  })

  const updateForm = async () => {
    const data = await generateForm("themMoiGoiThau", setIsLoading);
    setListJsonGenerate(data || []);
  };

  const handleSubmit = (values: any) => {
    const dataSubmit = {
      ...values?.goiThau,
      id: values?.goiThau?.id || detailGoiThau?.id,
      // lstItem: listItems,
    };
    (values?.goiThau?.id || detailGoiThau?.id) ? handlerUpdateGoiThau(dataSubmit) : handlerADDGoiThau(dataSubmit)
  };

  const handlerUpdateGoiThau = async (values?: any) => {
    try {
      setIsLoading(true);
      const { data } =
        await updateGoiThau(values?.goiThau?.id || detailGoiThau?.id, values)
      if (CODE.SUCCESS === data?.code) {
        setDetailGoiThau(data?.data);
        setIsLoading(false);
        toast.success("Cập nhật gói thầu thành công");
        onHide()
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  }

  const handlerADDGoiThau = async (values?: any) => {
    try {
      setIsLoading(true);
      const { data } = await addGoiThau(values);
      if (CODE.SUCCESS === data?.code) {
        setDetailGoiThau(data?.data);
        setIsLoading(false);
        toast.success("Thêm gói thầu thành công");
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  }

  useEffect(() => {
    updateForm();
  }, []);

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
                <div className="spaces mt-4 title">Thêm mới gói thầu</div>
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10 ">
                <h4 className="text-title fw-bold fs-5 spaces mb-5">Thông tin chung</h4>
                <GenerateFormComponent
                  listFieldAuto={listJsonGenerate}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  modelID={"goiThau"}
                  customComponent={DynamicComponent}
                />
              </div>
            </Modal.Body>
            <div className="bottom-button d-flex justify-content-end w-100 bg-white spaces py-8">
              <Button type="submit" className="btn btn-fill min-w-90px spaces mr-10">
                Lưu
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DialogThemMoiGoiThau;
