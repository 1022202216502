import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;

export const searchGoiThau = (searchObj: any) => {
  const url = `${API}/bids/search`;
  return axios.get(url);
};

export const addGoiThau = (data: any) => {
  const url = `${API}/bids`;
  return axios.post(url, data);
};

export const updateGoiThau = (id: string, data: any) => {
  const url = `${API}/bids/${id}`;
  return axios.put(url, data);
};

export const getDetailGoiThau = (id?: any) => {
  const url = `${API}/bids/${id}`;
  return axios.get(url);
};

export const getDetailVatTu = (id?: any) => {
  const url = `${API}/bid-item/themDanhSachVatTu/${id}`;
  return axios.get(url);
};

export const searchVT = (searchObject?: any) => {
  let url = API + `/bid-item/get-by-bid-id`;
  return axios.post(url, searchObject);
};

export const addVatTu = (data: any) => {
  const url = `${API}/bid-item/themDanhSachVatTu`;
  return axios.post(url, data);
};

export const updateVatTu = (id: string, data: any) => {
  const url = `${API}/bid-item/themDanhSachVatTu/${id}`;
  return axios.put(url, data);
};