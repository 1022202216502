import axios from "axios";
import { SearchObject } from "../models/ThongKeModel";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { paramsConfig, urlParamsConfig } from "../../utils/ParamsUtils";
const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_PATH_DS_HOP_DONG = API_PATH + '/contracts';
const API_PATH_DETAIL_THONG_KE = API_PATH + '/item';
const API_PATH_DS_TAB_FORM = API_PATH + '/item';
const API_PATH_THONG_KE_VAT_TU = API_PATH + '/item';
const API_PATH_NHOM_VAT_TU = API_PATH + '/term-classes';


export const searchByPage = (searchObject?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/search`;
  return axios.post(url, searchObject);
};
export const addVatTu = (data: any, modelId?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/create-list-model`+`/${modelId}`;
  return axios.post(url, data);
};
export const updateVatTu = (data: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/update-list-model/` + `${data?.lstModelType?.id}`;
  return axios.put(url, data);
};
export const updateHopDong = (data: any) => {
  let url = API_PATH_DS_HOP_DONG;
  return axios.put(url, data);
};
export const getDetailThongKe = (data: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/${data?.code}` + `/${data?.id}`;
  return axios.get(url);
}
export const getDetailVatTuView = (data: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/${data?.id}`;
  return axios.get(url);
}
export const getDetailVatTuEdit = (data: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/edit` + `/${data?.id}`;
  return axios.get(url);
}
export const getListFormTab = (string?: string, data?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/get-form-tab/` + `${string || "VIEW"}` + `/${data?.id || 1}`;
  return axios.get(url);
};
export const getListNhomVatTu = (data: any) => {
  let url = API_PATH_NHOM_VAT_TU + `/terms?classIds=1,2,3,4`;
  return axios.get(url);
}

export const deleteItem = (id: string) => {
  const url = `${API_PATH_THONG_KE_VAT_TU}` + `/${id}`;
  return axios.delete(url);
};

export const searchListThau = (searchObject?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/get-bids-by-item-id`;
  return axios.post(url, searchObject);
};