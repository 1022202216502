import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'

type Props = {
    listMenuItems: any[]
    color?: "fill" | "outlined"
    className?: string
    menuItemsClassName?: string
    label: string
    primaryItemLabel?: string
    drop?: "up" | "down" | "start" | "end"
    onClick?: () => void;
    onClickItem: (item: any) => void;
}

const CustomDropdownButtonChoose = (props: Props) => {
    let {
        listMenuItems = [],
        color = "outlined",
        drop = "down",
        className,
        menuItemsClassName,
        label,
        onClickItem,
        primaryItemLabel,
        onClick
    } = props;
    const [data, setData] = useState<any>("")
    const handlerOnClickItem = (item: any) => {
        if (!item) return
        onClickItem(item)
        setData(item?.name)
    }
    useEffect(() => {
        !data && setData(listMenuItems[0]?.name)
        !data && onClickItem(listMenuItems[0])
    }, [listMenuItems])

    return (
        <Dropdown className="dropdown-btn menu-icon w-100" drop={drop}>
            <Dropdown.Toggle className={`${color === "fill" ? "btn-fill" : "btn-navy-outlined"} ${className}`}>
                {data}
                <i className="bi bi-caret-down-fill text-pri ms-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className={` ${drop}-100 my-10 ${menuItemsClassName}`}>
                {listMenuItems?.map((item: any, index: number) => (
                    <Dropdown.Item className=" border-bottom w-100" onClick={() => handlerOnClickItem(item)} key={index}>
                        <div className="d-flex flex-column spaces px-10 py-2">
                            <p className="text-pri mb-0 text-truncate">{item?.name || ""}</p>
                            <p className="mb-0 fw-light text-truncate">{item?.desc || ""}</p>
                        </div>
                    </Dropdown.Item>
                ))}
                {primaryItemLabel &&
                    <Dropdown.Item className="p-0" onClick={onClick}>
                        <div className="spaces px-20 py-12 line-height-30">
                            <span className="fw-bold text-pri text-center">{primaryItemLabel || ""}</span>
                        </div>
                    </Dropdown.Item>
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default CustomDropdownButtonChoose