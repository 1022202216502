import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../appContext/AppContext";
import { generateForm } from "../../../utils/AppFunction";
import GenerateFormComponent from "../../../component/generate-form/GenerateFormHTMLNoValid";

type Props = {
  warehouseExportType?: any
};

const ThongTinPhieu = ({
  warehouseExportType,
}: Props) => {

  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    handleChange
  } = useFormikContext<any>();
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);

  useEffect(() => {
    getListform();
  }, [warehouseExportType.modelId]);

  const getListform = async () => {
    let data = await generateForm(warehouseExportType?.modelId, setIsLoading)
    setListJsonGenerate(data)
  }
  
  return (
    <div className="bg-white spaces mb-1 pb-15 fl-1 pl-16 pr-16">
      <GenerateFormComponent
        listFieldAuto={listJsonGenerate}
        modelID="phieuXuatKhoAdd"
        handleSubmit={handleSubmit}
        setValues={setValues}
        setTouched={setTouched}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
        isValid={isValid}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ThongTinPhieu;
