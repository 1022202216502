import CustomTabMenu from "../component/CustomTabMenu";
import { danhSachTabKhoVatTu } from "./Constant";

export const KhoVatTu: React.FC= () => {

  return (
   <CustomTabMenu
        danhsachTabs={danhSachTabKhoVatTu}
        className="spaces pl-0"
   />
  );
};

export default KhoVatTu;
